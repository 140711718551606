import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainSidenavService } from '../../../../main-sidenav.service';
import {
  ProgramsService,
  ProgramFull
} from '../../../../users-api';

@Component({
  selector: 'app-programs-edit',
  templateUrl: './programs-edit.component.html',
  styleUrls: ['./programs-edit.component.scss']
})
export class ProgramsEditComponent implements OnInit {
  loading = true;
  program: ProgramFull;
  originalProgram: ProgramFull;
  companyId = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private programsApi: ProgramsService,
    public mainSidenavService: MainSidenavService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.pipe(switchMap((params: ParamMap) => {
      this.companyId = params.get('companyId');
      const programId = params.get('programId');
      return this.programsApi.retrieveProgram(this.companyId, programId);
    })).subscribe((program: ProgramFull) => {
      this.loading = false;
      this.program = program;
      this.originalProgram = { ...program };
    }, error  => {
      this.loading = false;
      console.error('Reading program', error);
      this.snackBar.open('Error reading program: ' + error);
    });
  }

  goToProgramsList() {
    this.router.navigate(['pages', 'companies', this.companyId, 'programs']);
  }

  onEditorCancel() {
    this.goToProgramsList();
  }

  onEditorOK(program: ProgramFull) {
    program.id = this.originalProgram.id;
    program.company_id = this.companyId;
    if (program !== this.originalProgram) {
      this.loading = true;
      this.programsApi.updateProgram(this.companyId, this.originalProgram.program_id, program)
        .subscribe(() => {
          this.loading = false;
          this.goToProgramsList();
        },
        error => {
          console.error('Error calling updateProgram', error);
          this.loading = false;
          try {
            const data = error.error;
            this.snackBar.open(data.message, 'OK');
          } catch (ex) {
            this.snackBar.open('Error llamando updateProgram: ' + error.toString(), 'OK');
          }

        });
    }
  }
}
