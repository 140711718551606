import { Observer } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';
import { StorageService } from './../../../../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { indicator } from '../indicator.model';
import { IndicatorsService } from '../../indicators.service';


export class IndicatorDataSource extends DataSource<indicator> {
  observer: Observer<indicator[]>;

  refreshData(data: indicator[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<indicator[]> {
    return new Observable((observer: Observer<indicator[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}
@Component({
  selector: 'app-indicator-list',
  templateUrl: './indicator-list.component.html',
  styleUrls: ['./indicator-list.component.scss']
})
export class IndicatorListComponent implements OnInit {
  loading = false;
  pageSize = 100;
  dataSource = new IndicatorDataSource();
  appIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  nameCategory = new UntypedFormControl('');
  currentAppId: string;
  allowedAppIds: string[];
  allowAllAppIds = false;
  requiredFields = true;
  data: indicator[];
  resultsFound = true;
  displayedColumns = ['id'];
  hoveredRow: any;
  dataSearch: indicator[];
  dataFilter: indicator[];
  constructor(
    private router: Router,
    private storageService: StorageService,
    private indicatorsService: IndicatorsService,
    private authService: AuthService,
  ) {

  }

  async ngOnInit() {
    await this.initAllowedIds();
   this.getIndicator();
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];



  }

  getIndicator(appId?: string, name?: string) {
    if(appId){
      this.requiredFields = true;
    } else {
      this.requiredFields = false;
      this.data = [];
      this.dataSource.refreshData(this.data);
      return;
    }
    this.loading = true;
    this.indicatorsService.getIndicators(appId).toPromise()
    .then(async(data)=>{
      const indicators = data.data;
      console.log('indicators : ' , indicators, indicators.length);
      
      if (indicators.length === 500) {
       const page2 = (await this.indicatorsService.getIndicators(appId,indicators[indicators.length - 1].id).toPromise()).data
       data.data = [...data.data,...page2]
      }
      this.data = data.data;
      this.data = appId ? this.data.filter(x => x.app_id === appId) : this.data;
      this.data = name ? this.data.filter(x => x.name === name) : this.data;

      this.data = this.data.sort(function(a,b){
        return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
      });

      this.dataSource.refreshData(this.data);
      this.loading= false;
      this.resultsFound = this.data.length > 0 ? true : false;

      if(!name){
        this.dataSearch = this.data;
        this.dataFilter = this.data;
      }
    });
  }

  /* onUserSelected(user: AppUserOutput) {} */

  onAppSelected(appId: string) {
    this.appIdFilter$.next(appId);
    this.currentAppId = appId;
    this.getIndicator(this.currentAppId);
  }



  resetApp() {
    this.currentAppId = null;
  }

  onEdit(item: indicator) {
    this.router.navigate(['pages', 'indicators', 'edit',item.id]);
  }
  onCreate(){
    this.router.navigate(['pages', 'indicators', 'add']);
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }


  clearData(value){
    const val = String(value.target.value).toLowerCase();
    if(!val){
      this.getIndicator(this.currentAppId);
    } else {
      this.dataFilter = this.dataSearch.filter(x => String(x.name).toLowerCase().includes(val));
    }
  }

  onIdOptionSelected(option){
    const name = option.option.value;
    this.dataFilter = name ? this.data.filter(x => x.name == name) : this.data;
    this.dataSource.refreshData(this.dataFilter);
  }

  goToIcon(url: string){
    window.open(url, '_blank');
    return false;
  }

}
