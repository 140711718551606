import { Component, OnInit } from '@angular/core';
import {
  AccountTypesList,
  AccountTypeOutput,
} from '../../../../models/points/models';
import { MatTableDataSource } from '@angular/material/table';
import { PointsService } from '../../../../services/points.service';
@Component({
  selector: 'app-type-accounts',
  templateUrl: './type-accounts.component.html',
  styleUrls: ['./type-accounts.component.scss'],
})
export class TypeAccountsComponent implements OnInit {
  accounts: AccountTypeOutput[] = [];
  loader = false;
  dataSource: MatTableDataSource<AccountTypeOutput>;
  displayedColumns = ['Id', 'name', 'pointName', 'edit'];
  constructor(private pointsService: PointsService) {}
  ngOnInit() {
    this.loader = true;
    this.getTypeAccounts();
  }
  getTypeAccounts() {
    this.pointsService.listAccountTypes().subscribe((res: AccountTypesList) => {
      this.accounts.push(...res.data);

      this.dataSource = new MatTableDataSource(this.accounts);
      this.loader = false;
    });
  }

  searchAccount(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }
}
