import { Component, HostListener, OnInit } from '@angular/core';
import { AppsUtilService } from '../../services/apps-util.service';
import { ProgramsUtilService } from '../../services/programs-util.service';
import { AppUsersService } from '../../users-api';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PointsService } from '../../services/points.service';
import { OrdersService } from '../../services/orders.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountOutputList } from '../../../app/models/points/models';
import { TransactionTransformService } from '../../services/data-transform/transaction-transform-service';
import { OrderTransformService } from '../../services/data-transform/order-transform-service';
 

@Component({
  selector: 'app-consolidado-puntos-pedidos',
  templateUrl: './consolidado-puntos-pedidos.component.html',
  styleUrls: ['./consolidado-puntos-pedidos.component.scss'],
  providers: [AppUsersService]
})
export class ConsolidadoPuntosPedidosComponent implements OnInit {

  width = (window.innerWidth - 30);
  isLoadingUserSearch = false;
  isLoadingUserAccounts = false;
  isLoadingTransactions = false;
  isLoadingOrders = false;

  apps: any[] = [];
  programs: any[] = [];
  selectedAppId: string;
  searchValue: string;
  selectedOption: string;
  userList: any[];

  // Variables para las transacciones
  transactionList: any[];
  paginatedTransactionList: any[] = [];
  currentTnxPage: number = 0;
  pageTnxSize: number = 10;
  totalTransactions: number = 0;

  // Variables para las ordenes
  orderList: any[];
  paginatedOrderList: any[] = [];
  currentOrderPage: number = 0;
  pageOrderSize: number = 10;
  totalOrders: number = 0;

  options = [
    { label: 'Usuario', value: 'username' },
    { label: 'Nombre', value: 'name' },
    { label: 'Apellido', value: 'lastName' },
    { label: 'Correo Electrónico', value: 'email' },
    { label: 'Teléfono', value: 'phone' }
  ];
  displayedColumns: string[] = ['id', 'username', 'email', 'phone', 'enabled'];
  selectedUserId: string;
  userSelectedActiveAccounts: AccountOutputList;
  selectedAccountId: string;

  private searchValueChanged: Subject<string> = new Subject<string>();

  constructor(
    private appsUtilService: AppsUtilService,
    private programsUtilService: ProgramsUtilService,
    private usersApi: AppUsersService,
    private pointsService: PointsService,
    private orderService: OrdersService,
    private transactionTransformService: TransactionTransformService,
    private orderTransformService: OrderTransformService
  ) {
    this.searchValueChanged.pipe(
      debounceTime(700)
    ).subscribe(value => {
      this.searchUsers();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;
    this.width = (width - 30);
  }

  async ngOnInit(): Promise<void> {
    this.apps = await this.appsUtilService.getApps();
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.searchUsers();
    }
  }

  async onAppSelected(appId: string): Promise<void> {
    this.selectedAppId = appId;
    this.searchUsers();
  }

  onSearchValueChanged(value: string): void {
    this.searchValueChanged.next(value);
  }

  async searchUsers(): Promise<void> {
    this.userList = [];
    this.userSelectedActiveAccounts = { data: [] };
    this.selectedUserId = '';
    this.selectedAccountId = '';
    this.transactionList = [];

    let filter = '';

    if (this.selectedAppId) {
      filter += `app_id eq "${this.selectedAppId}"`;
    }

    if (this.selectedAppId && this.selectedOption && this.searchValue) {
      this.isLoadingUserSearch = true;
      const trimmedValue = this.searchValue.trim();

      switch (this.selectedOption) {
        case 'username':
          filter += `${filter ? ' and ' : ''}username sw "${trimmedValue}"`;
          break;
        case 'name':
          filter += `${filter ? ' and ' : ''}personal_data.names_lowercase sw "${trimmedValue}"`;
          break;
        case 'lastName':
          filter += `${filter ? ' and ' : ''}personal_data.names_lowercase sw "${trimmedValue}"`;
          break;
        case 'email':
          filter += `${filter ? ' and ' : ''}email sw "${trimmedValue}"`;
          break;
        case 'phone':
          filter += `${filter ? ' and ' : ''}phone sw "${trimmedValue}"`;
          break;
        default:
          console.warn('Opción seleccionada no válida');
      }

      this.usersApi.findAppUsers(
        '0',
        undefined,
        undefined,
        undefined,
        filter
      ).subscribe(
        (response) => {
          this.userList = response.data;
          this.isLoadingUserSearch = false;
        },
        (error) => {
          console.error('Error searching users:', error);
        }
      );
    }
  }

  onUserSelected(userId: string) {
    this.isLoadingUserAccounts = true;
    this.isLoadingOrders = true;
    this.selectedUserId = userId;

    this.userList = this.userList.filter(user => user.id === userId);

    this.pointsService.listAccountsByUIDs([userId]).subscribe(
      (response: AccountOutputList) => {
        const accounts = response[userId];

        this.userSelectedActiveAccounts = accounts
          ? accounts.filter(account => account.program_match === true)
          : [];
        this.isLoadingUserAccounts = false;
        this.orderService.getOrdersByUserId(this.selectedUserId).subscribe(
          (orderList: any) => {
            this.orderList = this.orderTransformService.addDisplayFields(orderList.data.flat());
            this.totalOrders = this.orderList.length;
            this.isLoadingOrders = false;
            this.updatePaginatedOrders();
          },
          (error: HttpErrorResponse) => {
            this.orderList = [];
            this.totalOrders = 0;
            this.isLoadingOrders = false;
            console.error('Error al obtener transacciones:', error);
          }
        )
      },
      (error: HttpErrorResponse) => {
        console.error('Error al buscar las cuentas del usuario:', error);
      }
    );
  }

  onAccountSelected(accountId: string): void {
    this.isLoadingTransactions = true;
    this.selectedAccountId = accountId;

    this.pointsService.listTransactionsByAccountId(accountId).subscribe(
      (transactionsList: any) => {
        this.transactionList = transactionsList.data.flat();
        this.transactionList = this.transactionTransformService.addDisplayFields(this.transactionList);
        this.totalTransactions = this.transactionList.length;
        this.updatePaginatedTransactions();
        this.isLoadingTransactions = false;
      },
      (error: HttpErrorResponse) => {
        console.error('Error al obtener transacciones:', error);
      }
    );
  }

  // Paginación de ordenes
  updatePaginatedOrders(): void {
    const start = this.currentOrderPage * this.pageOrderSize;
    const end = start + this.pageOrderSize;
    this.paginatedOrderList = this.orderList.slice(start, end);
  }

  nextPageOrders(): void {
    if ((this.currentOrderPage + 1) * this.pageOrderSize < this.totalOrders) {
      this.currentOrderPage++;
      this.updatePaginatedOrders();
    }
  }

  previousPageOrders(): void {
    if (this.currentOrderPage > 0) {
      this.currentOrderPage--;
      this.updatePaginatedOrders();
    }
  }

  getTotalOrdersPages(): number {
    return Math.ceil(this.totalOrders / this.pageOrderSize);
  }

  // Paginación de ordenes
  updatePaginatedTransactions(): void {
    const start = this.currentTnxPage * this.pageTnxSize;
    const end = start + this.pageTnxSize;
    this.paginatedTransactionList = this.transactionList.slice(start, end);
  }

  nextPageTransactions(): void {
    if ((this.currentTnxPage + 1) * this.pageTnxSize < this.totalTransactions) {
      this.currentTnxPage++;
      this.updatePaginatedTransactions();
    }
  }

  previousPageTransactions(): void {
    if (this.currentTnxPage > 0) {
      this.currentTnxPage--;
      this.updatePaginatedTransactions();
    }
  }

  getTotalTnxPages(): number {
    return Math.ceil(this.totalTransactions / this.pageTnxSize);
  }

  // Funciones de utilidad
  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  isObject(value: any): boolean {
    return value && typeof value === 'object' && !Array.isArray(value);
  }

  getObjectEntries(obj: any): Array<{ key: string, value: any }> {
    return Object.entries(obj).map(([key, value]) => ({ key, value }));
  }

}
