import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoces-report',
  templateUrl: './invoces-report.component.html',
  styleUrls: ['./invoces-report.component.scss']
})

export class InvocesReportComponent implements OnInit {

  width = (window.innerWidth - 30)
  constructor() { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;
    this.width = (width - 30)
  }

  ngOnInit() {

  }

}
