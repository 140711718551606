import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CdkTableModule } from '@angular/cdk/table';

import { MainSidenavComponent } from '../main-sidenav/main-sidenav.component';
import { AppSelectorComponent } from './app-selector/app-selector.component';
import { AppUserSelectorComponent } from './app-user-selector/app-user-selector.component';
import { ProgramSelectorComponent } from './program-selector/program-selector.component';
import { CompanySelectorComponent } from './company-selector/company-selector.component';
import { LoaderFullComponent } from './loader-full/loader-full.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HasPermissionsDirective } from '../directives/has-permissions.directive';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CdkTableModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatCardModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTableModule,
    MatSelectModule,
    MatTooltipModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTreeModule,
    MatSlideToggleModule,
    MatInputModule,
    MatFormFieldModule,
    NgxSpinnerModule ,
    HasPermissionsDirective
  ],
  declarations: [MainSidenavComponent, AppSelectorComponent, AppUserSelectorComponent, ProgramSelectorComponent, CompanySelectorComponent,LoaderFullComponent],
  exports: [
    RouterModule,
    MainSidenavComponent,
    CdkTableModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatCardModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTableModule,
    MatSelectModule,
    MatTooltipModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDatepickerModule,
    MatTreeModule,
    MatMomentDateModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatInputModule,
    MatFormFieldModule,
    AppSelectorComponent,
    AppUserSelectorComponent,
    ProgramSelectorComponent,
    CompanySelectorComponent,
    LoaderFullComponent
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es' }],
})
export class AppCommonModule {}
