import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, Observer, forkJoin } from 'rxjs';
import { StorageService } from '../../services/storage.service';

import {
  AppsService,
  AppsList,
  App as AppInterface,
  AppOutput,
} from '../../users-api';
import { MainSidenavService } from '../../main-sidenav.service';
import {HttpErrorResponse} from '@angular/common/http';

export class AppsDataSource extends DataSource<AppOutput> {
  observer: Observer<AppOutput[]>;

  refreshData(data: AppOutput[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<AppOutput[]> {
    return Observable.create((observer: Observer<AppOutput[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss'],
  providers: [AppsService],
})
export class AppsComponent implements OnInit {
  displayedColumns = ['id', 'name', 'created_at', 'updated_at', 'edit'];
  dataSource = new AppsDataSource();
  loading = false;
  pageSize = 100;
  pagerSteps = [100, 200, 500, 1000];
  nextCursor: string;
  prevCursor: string;
  firstCursor: string;
  hoveredRow: any;
  canViewAnyApp = false;

  constructor(
    private appsApi: AppsService,
    private snackBar: MatSnackBar,
    public mainSidenavService: MainSidenavService,
    private router: Router,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    this.updateData();
  }

  processHttpError(log: string, error: HttpErrorResponse) {
    console.error(log, error);
    let msg = 'Error de comunicación';
    if (error.status >= 400 && error.status < 500) {
      msg = 'Acceso denegado';
    }
    msg += `: ${error.status} ${error.statusText}: ${JSON.stringify(error.error)}`;
    this.snackBar.open(msg, 'OK');
  }

  getAppsFromAppIds(appIds: string[]) {
    this.loading = true;
    const observables = appIds.map(appId => this.appsApi.retrieveApp(appId));
    forkJoin(observables).subscribe(
      (apps: AppOutput[]) => {
        this.loading = false;
        this.dataSource.refreshData(apps);
        this.nextCursor = null;
        this.prevCursor = null;
        this.firstCursor = null;
      },
      (error) => {
        this.loading = false;
        this.processHttpError('Error getting authorized_apps', error);
      });
  }

  updateData(nextCursor?: string, prevCursor?: string, firstCursor?: string) {
    this.loading = true;
    this.appsApi
      .findApp(this.pageSize.toString(), nextCursor, prevCursor, firstCursor)
      .subscribe(
        (list: AppsList) => {
          this.loading = false;
          this.canViewAnyApp = true;
          this.dataSource.refreshData(list.data);
          if ('next_page_cursor' in list) {
            this.nextCursor = list.next_page_cursor;
          }
          if ('prev_page_cursor' in list) {
            this.prevCursor = list.prev_page_cursor;
          }
          if ('first_page_cursor' in list) {
            this.firstCursor = list.first_page_cursor;
          }
        },
        (error) => {
          this.canViewAnyApp = false;
          // Some users do not have permission to view all apps
          // then use profile apps
          if (error.status === 403) {
            const profile = this.storageService.get('profile');
            if (profile && profile.authorized_apps && profile.authorized_apps.length) {
              this.getAppsFromAppIds(profile.authorized_apps);
              return;
            }
          }
          this.loading = false;
          this.processHttpError('Error on appsApi.findApp', error);
        }
      );
  }

  itemHasCreatedAt(item: Object) {
    return 'created_at' in item;
  }

  itemHasUpdatedAt(item: Object) {
    return 'updated_at' in item;
  }

  loadFirstPage() {
    this.updateData();
    return false;
  }

  loadPrevPage() {
    if (this.prevCursor && this.firstCursor) {
      this.updateData(undefined, this.prevCursor, this.firstCursor);
    }
    return false;
  }

  loadNextPage() {
    if (this.nextCursor && this.firstCursor) {
      this.updateData(this.nextCursor, undefined, this.firstCursor);
    }
    return false;
  }

  pageSizeCtrlChanged() {
    this.updateData();
    return false;
  }

  onEditApp(app: AppInterface) {
    this.router.navigate(['pages', 'apps', app.id]);
    return false;
  }

  onCreateApp() {
    this.router.navigateByUrl('/pages/apps/new-app');
    return false;
  }

  getRowBackgroundColor(row: unknown) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }
}
