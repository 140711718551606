import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FlatTreeControl, TreeControl } from '@angular/cdk/tree';
import { AuthService } from '../services/auth.service';
import { UtilsService } from '../services/utils.service';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main-sidenav',
  templateUrl: './main-sidenav.component.html',
  styleUrls: ['./main-sidenav.component.scss'],
})
export class MainSidenavComponent implements OnInit {
  user: firebase.User;
  homeActive: boolean;
  @ViewChild('tree', { static: true }) tree;
  constructor(
    public auth: AuthService,
    public route: Router,
    private navControl: ActivatedRoute,
    public util: UtilsService,
    private cd : ChangeDetectorRef
  ) {}

  private _transformer = (node, level) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      path: node.path,
      icon: node.icon,
    };
  };
  ngOnInit() {
    this.dataSource.data = this.util.getRoutesApplication();
    this.getUser();
  }

  treeControl = new FlatTreeControl(
    (node: any) => node.level,
    (node: any) => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  hasChild = (_: number, node) => node.expandable;
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  getUser() {
    return this.auth.getUser().subscribe((user: firebase.User) => {
      this.user = user;
    });
  }

  logout() {
    this.auth.logOut();
    this.route.navigate(['/login']);
  }

  ngAfterViewInit() {
    this.openActiveTree();
  }

  openActiveTree() {
    const pathName = window.location.pathname;

    if (pathName.includes('home')) {
      this.homeActive = true;
    }
  }
   getActive(node){
    let res = this.homeActive && node?.name === 'Home' ? 'is-active' : ''
    this.cd.detach();
   return  res
  }
}
