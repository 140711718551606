import { ListExhibitionsComponent } from './exhibitions/exhibition/list-exhibitions/list-exhibitions.component';
import { IndicatorAddComponent } from './indicators/indicators/indicator-add/indicator-add.component';
import { IndicatorListComponent } from './indicators/indicators/indicator-list/indicator-list.component';
import { SectionsListComponent } from './indicators/sections/sections-list/sections-list.component';
import { SectionsAddComponent } from './indicators/sections/sections-add/sections-add.component';
import { ExhibitionsReportComponent } from './exhibitions-report/exhibitions-report.component';
import { ExhibicionesEditComponent } from './answers-exhibiciones/exhibiciones-edit/exhibiciones-edit.component';
import { AnswersExhibicionesComponent } from './answers-exhibiciones/answers-exhibiciones.component';
import { LimitsRedemptionComponent } from './limits-redemption/limits-redemption.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from '../auth-guard.service';
import { AppsComponent } from './apps/apps.component';
import { AppUsersComponent } from './app-users/app-users.component';
import { AppsViewerComponent } from './apps/apps-viewer/apps-viewer.component';
import { AppsAddComponent } from './apps/apps-add/apps-add.component';
import { AppUsersViewerComponent } from './app-users/app-users-viewer/app-users-viewer.component';
import { AppUsersAddComponent } from './app-users/app-users-add/app-users-add.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompaniesAddComponent } from './companies/companies-add/companies-add.component';
import { CompaniesViewerComponent } from './companies/companies-viewer/companies-viewer.component';
import { ProgramsComponent } from './companies/programs/programs.component';
import { ProgramsAddComponent } from './companies/programs/programs-add/programs-add.component';
// import { ProgramsEditorComponent } from './companies/programs/programs-editor/programs-editor.component';
import { ProgramsEditComponent } from './companies/programs/programs-edit/programs-edit.component';
import { BrandsListComponent } from './pointz/brands-list/brands-list.component';
import { BrandsEditComponent } from './pointz/brands-edit/brands-edit.component';
import { CanjesComponent } from './pointz/canjes/canjes.component';
import { AlliesListComponent } from './pointz/allies-list/allies-list.component';
import { AlliesEditComponent } from './pointz/allies-edit/allies-edit.component';
import { SalesPointsListComponent } from './pointz/sales-points-list/sales-points-list.component';
import { SalesPointsEditComponent } from './pointz/sales-points-edit/sales-points-edit.component';
import { AccountsComponent } from './points/components/accounts/accounts.component';
import { AccountsEditComponent } from './points/components/accounts-edit/accounts-edit.component';
import { TypeAccountsComponent } from './points/components/type-accounts/type-accounts.component';
import { TypeAccountsEditComponent } from './points/components/type-accounts-edit/type-accounts-edit.component';
import { ServiceAccountsComponent } from './service-accounts/service-accounts-list/service-accounts.component';
import { ServiceAccountsEditComponent } from './service-accounts/service-accounts-edit/service-accounts-edit.component';
import { HomeComponent } from './home/home.component';
import { AccountDetailComponent } from './points/components/account-detail/account-detail.component';
import { AccountAddPointsComponent } from './points/components/account-add-points/account-add-points.component';
import { CmsStrapiComponent } from './cms-strapi/cms-strapi.component';
import { CmsDrupalComponent } from './cms-drupal/cms-drupal.component';
import { ChallengesComponent } from './answers-challenges/challenges.component';
import { ChallengesEditComponent } from './answers-challenges/challenges-edit/challenges-edit.component';
import { ImportsComponent } from './imports/imports.component';
import { ConsolidadoPuntosPedidosComponent } from './consolidado-puntos-pedidos/consolidado-puntos-pedidos.component';
import { InvoicesComponent } from './answers-invoices/invoices.component';
import { InvoicesEditComponent } from './answers-invoices/invoices-edit/invoices-edit.component';
import { InvocesReportComponent } from './invoces-report/invoces-report.component';
import { ChallengesReportComponent } from './challenges-report/challenges-report.component';
import { RecognitionsFormComponent } from './recognitions/recognitions-form/recognitions-form.component';
import { RecognitionsComponent } from './recognitions/recognitions.component';
import { CategoryListComponent } from './indicators/categories/category-list/category-list.component';
import { CategoryAddComponent } from './indicators/categories/category-add/category-add.component';
import { RoutesCategoriesAllies } from './pointz/categories/categories-routing.module';
import { BagsFormComponent } from './bags/bags-form/bags-form.component';
import { BagsComponent } from './bags/bags.component';
import { BagTransationFormComponent } from './bags/bag-transation-form/bag-transation-form.component';
import { BagTransactionComponent } from './bags/bag-transaction/bag-transaction.component';
import { FormTablesExhibitionsComponent } from './exhibitions/tables/form-tables-exhibitions/form-tables-exhibitions.component';
import { ListTablesExhibitionsComponent } from './exhibitions/tables/list-tables-exhibitions/list-tables-exhibitions.component';
import { FormExhibitionsComponent } from './exhibitions/exhibition/form-exhibitions/form-exhibitions.component';
import { permissionsGuard } from '../guards/permissions.guard';
import { PERMISSIONS } from '../services/permissions/permissions.service';

export const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'apps', component: AppsComponent,
        canActivate: [permissionsGuard],
        data: {
          permissionValidate: [PERMISSIONS.VIEW_APPS]
        }
      },
      {
        path: 'apps/new-app', component: AppsAddComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_APPS,PERMISSIONS.UPDATE_APPS]
        }
      },
      {
        path: 'apps/:id', component: AppsViewerComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_APPS,PERMISSIONS.UPDATE_APPS]
        }
      },
      { path: 'app-users', component: AppUsersComponent 
        , canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_USERS,PERMISSIONS.UPDATE_USERS]
        }
      },
      { path: 'app-users/new-user', component: AppUsersAddComponent
        , canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_USERS]
        }
       },
      { path: 'app-users/:id', component: AppUsersViewerComponent
        , canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_USERS,PERMISSIONS.UPDATE_USERS]
        }
       },
      { path: 'companies', component: CompaniesComponent, 
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_COMPANIES]
        } },
      { path: 'companies/new', component: CompaniesAddComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_COMPANY]
        } 
       },
      { path: 'companies/:id', component: CompaniesViewerComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_COMPANIES,PERMISSIONS.UPDATE_COMPANY]
        } 
       },
      {
        path: 'companies/:companyId/programs',
        component: ProgramsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_PROGRAMS]
        } 
      },
      {
        path: 'service-accounts',
        component: ServiceAccountsComponent
        , canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_SERVICE_ACCOUNT,PERMISSIONS.UPDATE_SERVICE_ACCOUNT]
        }
      },
      {
        path: 'service-accounts/edit/:id',
        component: ServiceAccountsEditComponent
        , canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.UPDATE_SERVICE_ACCOUNT]
        }
      },
      {
        path: 'service-accounts/create',
        component: ServiceAccountsEditComponent
        , canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_SERVICE_ACCOUNT]
        }
      },
      {
        path: 'companies/:companyId/programs/new',
        component: ProgramsAddComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_PROGRAM]
        } 
      },
      {
        path: 'companies/:companyId/programs/:programId',
        component: ProgramsEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.UPDATE_PROGRAM,PERMISSIONS.VIEW_PROGRAMS]
        } 
      },
      {
        path: 'brands/list',
        component: BrandsListComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_BRAND_VIEW]
        }
      },
      {
        path: 'brands/edit/:id',
        component: BrandsEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_BRAND_UPDATE]
        }
      },
      {
        path: 'brands/create',
        component: BrandsEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_BRAND_CREATE]
        }
      },
      {
        path: 'allies',
        children: RoutesCategoriesAllies,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_COMMERCE_CREATE,PERMISSIONS.POINTS_COMMERCE_VIEW,PERMISSIONS.POINTS_COMMERCE_UPDATE,]
        }
        
      },
      {
        path: 'estado-canjes',
        component: CanjesComponent,
        
      },
      {
        path: 'allies/list',
        component: AlliesListComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_COMMERCE_VIEW]
        }
      },
      {
        path: 'allies/create',
        component: AlliesEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_COMMERCE_CREATE]
        }
      },
      {
        path: 'allies/edit/:id',
        component: AlliesEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_COMMERCE_UPDATE]
        }
      },
      {
        path: 'sales-point/list',
        component: SalesPointsListComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_SALES_LOCATION_VIEW]
        }
      },
      {
        path: 'sales-point/edit/:id',
        component: SalesPointsEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_SALES_LOCATION_UPDATE]
        }
      },
      {
        path: 'sales-point/create',
        component: SalesPointsEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_SALES_LOCATION_CREATE]
        }
      },
      {
        path: 'points/accounts',
        component: AccountsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_ACCOUNT]
        }
      },
      {
        path: 'points/accounts/create',
        component: AccountsEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_ACCOUNT]
        }
      },
      {
        path: 'points/accounts/:id',
        component: AccountDetailComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_ACCOUNT,PERMISSIONS.UPDATE_ACCOUNT]
        }
      },
      {
        path: 'points/accounts/:id/add-points',
        component: AccountAddPointsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.UPDATE_ACCOUNT,PERMISSIONS.CREATE_TRANSACTION]
        }
      },
      {
        path: 'points/type-accounts',
        component: TypeAccountsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_ACCOUNT_TYPE]
        }
      },
      {
        path: 'points/type-accounts/create',
        component: TypeAccountsEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_ACCOUNT_TYPE]
        }
      },
      {
        path: 'points/type-accounts/edit/:id',
        component: TypeAccountsEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.UPDATE_ACCOUNT_TYPE]
        }
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'cms',
        component: CmsStrapiComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_CMS]
        } 
      },
      {
        path: 'drupal',
        component: CmsDrupalComponent,
      },
      {
        path: 'challenges',
        component: ChallengesComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.ANSWER_CHALLENGE_VIEW]
        }
      },
      {
        path: 'invoices',
        component: InvoicesComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.ANSWER_INVOICE_VIEW]
        }
      },
      {
        path: 'exhibitions',
        component: AnswersExhibicionesComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.ANSWER_EXHIBITION_VIEW]
        }
      },
      {
        path: 'challenges/:id',
        component: ChallengesEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.ANSWER_CHALLENGE_UPDATE,PERMISSIONS.ANSWER_CHALLENGE_VIEW]
        }
      },
      {
        path: 'invoices/:id',
        component: InvoicesEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.ANSWER_INVOICE_UPDATE,PERMISSIONS.ANSWER_INVOICE_VIEW]
        }
      },
      {
        path: 'exhibitions/:id',
        component: ExhibicionesEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.ANSWER_EXHIBITION_UPDATE,PERMISSIONS.ANSWER_EXHIBITION_VIEW]
        }
      },
      {
        path: 'imports',
        component: ImportsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.IMPORTER_BANK_TRANSACTIONS,PERMISSIONS.IMPORTER_CENTRAL_DELIVERIES_UPDATER, PERMISSIONS.IMPORTER_KPI, PERMISSIONS.IMPORTER_CENTRAL_PREINVOICES_UPDATER,PERMISSIONS.IMPORTER_USERS_BY_APPID]
        }
      },
      {
        path: 'consolidado-puntos-pedidos',
        component: ConsolidadoPuntosPedidosComponent,
      },
      {
        path: 'invoices-report',
        component: InvocesReportComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.REPORT_INVOICE_VIEW]
        }
      },
      {
        path: 'exhibitions-report',
        component: ExhibitionsReportComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.REPORT_EXHIBITION_VIEW]
        }
      },
      {
        path: 'challenges-report',
        component: ChallengesReportComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.REPORT_CHALLENGE_VIEW]
        }
      },
      {
        path: 'recognitions',
        component: RecognitionsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.RECOGNITION_TYPE_VIEW]
        }
      },
      {
        path: 'recognitions/create',
        component: RecognitionsFormComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.RECOGNITION_TYPE_CREATE]
        }
      },
      {
        path: 'recognitions/edit/:id',
        component: RecognitionsFormComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.RECOGNITION_TYPE_UPDATE]
        }
      },
      {
        path: 'limits-redemption',
        component: LimitsRedemptionComponent,
      },
      {
        path: 'categories/list',
        component: CategoryListComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_CATEGORIES_KPI]
        }
      },
      {
        path: 'categories/add',
        component: CategoryAddComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_CATEGORIES_KPI]
        }
      },
      {
        path: 'categories/edit/:id/:appId',
        component: CategoryAddComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.UPDATE_CATEGORIES_KPI]
        }
      },
      {
        path: 'indicators/list',
        component: IndicatorListComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_KPI]
        }
      },
      {
        path: 'indicators/add',
        component: IndicatorAddComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_KPI]
        }
      },
      {
        path: 'indicators/edit/:id',
        component: IndicatorAddComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.UPDATE_KPI]
        }
      },
      {
        path: 'bags',
        component: BagsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_BAG]
        }
      },
      {
        path: 'bags/create',
        component: BagsFormComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_BAG]
        }
      },
      {
        path: 'bags/edit/:id',
        component: BagsFormComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.UPDATE_BAG]
        }
      },
      {
        path: 'bagtransaction/addTransaction/:bagId',
        component: BagTransationFormComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.UPDATE_BAG]
        }
      },
      {
        path: 'bagtransaction/:id',
        component: BagTransactionComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_BAG]
        }
      },
      {
        path: 'sections/list',
        component: SectionsListComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_SECTIONS_KPI]
        }
      },
      {
        path: 'sections/add',
        component: SectionsAddComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_SECTIONS_KPI]
        }
      },
      {
        path: 'sections/edit/:id',
        component: SectionsAddComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.UPDATE_SECTIONS_KPI]
        }
      },
      {
        path: 'exhibition/tables',
        component: ListTablesExhibitionsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_EXHIBITION_TABLE]
        }
      },
      {
        path: 'exhibition/tables/:appId/:id',
        component: FormTablesExhibitionsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.UPDATE_EXHIBITION_TABLE]
        }

      },
      {
        path: 'exhibition/tables/:appId',
        component: FormTablesExhibitionsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.CREATE_EXHIBITION_TABLE]
        }
      },
      {
        path: 'exhibition/forms',
        component: ListExhibitionsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.VIEW_EXHIBITION_FORM]
        }
      },
      {
        path: 'exhibition/forms/:appId/:id',
        component: FormExhibitionsComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.UPDATE_EXHIBITION_FORM,PERMISSIONS.CREATE_EXHIBITION_FORM ]
        }
      },
      {
        path: '**',
        redirectTo: 'home',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
