export const environment = {
  production: false,
  apikey: 'AIzaSyASNs4e0mvPg7AYhuGo2s0nA4OJheCwrSc',
  gluky: {
    app_id: 'gluky_admin',
  },
  gmApiKey: 'AIzaSyAhKdTC6kvQG5hfOGeKheKbsWBo1ZtkTXc',
  firebase: {
    users: {
      apiKey: 'AIzaSyAhKdTC6kvQG5hfOGeKheKbsWBo1ZtkTXc',
      authDomain: 'gluky-users-production.firebaseapp.com',
      databaseURL: 'https://gluky-users-production.firebaseio.com',
      projectId: 'gluky-users-production',
      storageBucket: 'gluky-users-production.appspot.com',
      messagingSenderId: '241391890274',
    },
    challenges: {
      apiKey: "AIzaSyC_D6U8TDkamjg228NpS-kiUjJox7HMyvY",
      authDomain: "gluky-challenges-prod.firebaseapp.com",
      projectId: "gluky-challenges-prod",
      storageBucket: "gluky-challenges-prod.appspot.com",
      messagingSenderId: "1007671929147",
      appId: "1:1007671929147:web:bf50197905eaf7b606ef1e"
    },
    dataimporters: {
      apiKey: "AIzaSyDWNV0sum73OHFKMtZ-6lEutnkUJ-3Pgrg",
      authDomain: "gnx-data-importers.firebaseapp.com",
      projectId: "gnx-data-importers",
      storageBucket: "gnx-data-importers.appspot.com",
      messagingSenderId: "442692362901",
      appId: "1:442692362901:web:ef4217b05597eeb80ff19c"
    }
  },
  usersApiBasePath: 'https://users-api.glukynet.com/v2',
  pointz_api: {
    uri_brands: 'https://api.pointz.co/main/v1/allies/brands',
    uri_exchanges: 'https://api.pointz.co/main/v1/exchange',
    uri_allies: 'https://api.pointz.co/main/v1/allies/commerce',
    uri_sales_points: 'https://api.pointz.co/main/v1/allies/sales-locations',
    uri_cloudinary: 'https://api.pointz.co/main/v1/cloudinary',
    base_url_new: 'https://api.pointz.co/main/v1',
  },
  points_api: {
    base_url: 'https://bank-api.glukynet.com/v1',
  },
  orders_api: {
    base_url: 'https://orders-api.glukynet.com/v1',
  },
  exhibition_api: {
    base_url: 'https://exhibition-admin-us-east1-cux1ukor.ue.gateway.dev/v1/apps',
  },
  recognitions_api: {
    apiKey: 'AIzaSyD4RRaDBXCdLNyYBcoL71Jhdemjv4UZMAo',
    base_url: 'https://recognitions-admin-us-east1-bryp80ke.ue.gateway.dev/v1',
  },
  bags_api: {
    apiKey: 'AIzaSyASNs4e0mvPg7AYhuGo2s0nA4OJheCwrSc',
    base_url: 'https://bolsas-admin-prod-mbsx99e.ue.gateway.dev/v1',
  },
  kpi_api: {
    apiKey: 'AIzaSyB0MFQ0AEUeZypiMLXOeBVWHJpwtqrnjtU',
    base_url: 'https://kpis-admin-us-east1-21cvo6vg.ue.gateway.dev/v1',
  },
  cloudinary_config: {
    cloud_name: 'glukygroup',
    apiKey: '112786663666772',
  },
  public_id_cloudinary: '/Brands/Brands-prod/',
  uploadPreset: 'Brands-prod',
  widget_cloudinary_config: {
    cloudName: 'glukygroup',
    api_key: '112786663666772',
    language: 'es',
    showAdvancedOptions: true,
    uploadPreset: 'Brands-prod',
    theme: 'minimal',
    showCompletedButton: false,
  },
  strapi_cms: {
    base_url: 'https://strapi.cms.glukynet.com/admin/auth/login'
  },
  drupal_cms: {
    base_url: 'https://gnxkxyhzbcfwi.devcloud.acquia-sites.com',
  },
  appDomain: 'admin.glukynet.com',
  redemption_rules_api:{
    apiKey: 'AIzaSyASNs4e0mvPg7AYhuGo2s0nA4OJheCwrSc',
    base_url: 'https://redemption-rule-admin-us-east1-auhkhvgd.ue.gateway.dev/v1',
  },
  reports:{
    invoiceUrl:"https://lookerstudio.google.com/embed/reporting/9da305a9-8c3b-449d-b918-5cc3b57c975d/page/nJj1C",
    exhibitionUrl:"https://datastudio.google.com/embed/reporting/3e900138-9cc1-4232-9fc9-2d3678c3cfcb/page/pMJ2C",
    challengeUrl:'https://datastudio.google.com/embed/reporting/9aff3db0-3ef2-46ec-b064-d31dcf5f4287/page/Ll2pC'
  }
};
