import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import {
  ChallengeAnswer,
  CHALLENGE_ANSWER_STATUSES,
  CHALLENGE_STATUS_OPTIONS,
  ChallengeStatusType,
} from '../../../models/answers/answers-models';
import {ChallengesService} from '../challenges.service';

@Component({
  selector: 'app-challenges-edit',
  templateUrl: './challenges-edit.component.html',
  styleUrls: ['./challenges-edit.component.scss']
})

export class ChallengesEditComponent implements OnInit {

  @Output() onCancel = new EventEmitter<void>();
  loading = false;
  challengeAnswer: ChallengeAnswer;
  newStatus: ChallengeStatusType;
  statuses = CHALLENGE_ANSWER_STATUSES;
  statusOptions = CHALLENGE_STATUS_OPTIONS;

  constructor(
    @Inject('ChallengesFirestore') private challengesFire: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    private challengesService: ChallengesService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.getChallengeById(params.id);
    });
  }

  async getChallengeById(id: string) {
    this.loading = true;
    const db = this.challengesFire.firestore;
    const docSnap = await db.doc(`ChallengesAnswers/${id}`).get();
    this.loading = false;
    if (!docSnap.exists) {
      console.error(`Challenge Answer '${id}' not found.`);
      this.challengeAnswer = null;
      return;
    }
    this.challengeAnswer = docSnap.data() as ChallengeAnswer;
    this.challengeAnswer.id = id;
    this.newStatus = this.challengeAnswer.status;
  }

  cancelClicked() {
    this.router.navigateByUrl('/pages/challenges');
  }

  okClicked() {
    if (this.newStatus === this.challengeAnswer.status) {
      this.router.navigateByUrl('/pages/challenges');
      return;
    }
    this.challengesService.updateChallengeStatus(this.challengeAnswer.id, this.newStatus)
      .then(
        () => this.router.navigateByUrl('/pages/challenges'),
        err => Swal.fire('Camibando Estado', err.message || err, 'error')
      );
  }
}
