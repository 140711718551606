import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router, Params } from '@angular/router';
import {
  ExhibitionAnswer,
  ChallengeStatusType,
  INVOICES_ANSWER_STATUSES,
  INVOICES_STATUS_OPTIONS
} from './../../../models/answers/answers-models';
import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { ExhibitionsService } from '../answers-exhibiciones.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exhibiciones-edit',
  templateUrl: './exhibiciones-edit.component.html',
  styleUrls: ['./exhibiciones-edit.component.scss']
})
export class ExhibicionesEditComponent implements OnInit {
  @Output() onCancel = new EventEmitter<void>();
  loading = false;
  exhibitionAnswer: ExhibitionAnswer;
  newStatus: ChallengeStatusType;
  statuses = INVOICES_ANSWER_STATUSES;
  statusOptions = INVOICES_STATUS_OPTIONS;
  displayedColumns: string[] = ['name', 'quantity', 'value'];
  rejectionCause;
  comment;
  rejectionCauses = [
    { label: 'Aprobado' },
    { label: 'Falta' },
    { label: 'Foto duplicada del mismo establecimiento' },
    { label: 'Foto duplicada en dos puntos de ventas diferentes' },
    { label: 'Fuera de fecha' },
    { label: 'Imagen borrosa' },
    { label: 'Imagen captura de pantalla' },
    { label: 'Imagen cortada' },
    { label: 'Imagen no cargó correctamente' },
    { label: 'Imagen no contiene el producto estrella' },
    { label: 'Imagen no corresponde con el reto' },
    { label: 'Imagen no valida' },
    { label: 'La imagen no es del producto correcto' },
    { label: 'No carga información del usuario' },
    { label: 'No cumple con la cantidad' },
    { label: 'Prueba' },
    { label: 'Puntaje parcial' }
  ];
  id;

  constructor(
    @Inject('ExhibitionsFirestore') private challengesFire: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    private exhibitionsService: ExhibitionsService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.getChallengeById(params.id);
      this.id = params.id;
    });
  }

  async getChallengeById(id: string) {
    this.loading = true;
    const db = this.challengesFire.firestore;
    const docSnap = await db.doc(`ExhibitionsAnswers/${id}`).get();
    this.loading = false;
    if (!docSnap.exists) {
      console.error(`Exhibition Answer '${id}' not found.`);
      this.exhibitionAnswer = null;
      return;
    }
    this.exhibitionAnswer = docSnap.data() as ExhibitionAnswer;

    console.log(this.exhibitionAnswer);
    this.exhibitionAnswer.id = id;
    this.newStatus = this.exhibitionAnswer.status;
    if (this.exhibitionAnswer) {
      if (!this.exhibitionAnswer.photos) {
        this.exhibitionAnswer.photos = [];
        if (this.exhibitionAnswer.photo) {
          this.exhibitionAnswer.photos.push({ type: 'camera', value: this.exhibitionAnswer.photo });
        }
        if (this.exhibitionAnswer.image) {
          this.exhibitionAnswer.photos.push({ type: 'file', value: this.exhibitionAnswer.image });
        }
      }
      this.rejectionCause = this.exhibitionAnswer.rejectionCause ? this.exhibitionAnswer.rejectionCause : '';
      this.comment = this.exhibitionAnswer.comment ? this.exhibitionAnswer.comment : '';
    }
    const city =  this.exhibitionAnswer.fields?.find((field)=> field.name  === 'city');
    const idNumber =  this.exhibitionAnswer.fields?.find((field)=> field.name  === 'idNumber');
    this.exhibitionAnswer.city = city  ? city.value : '';
    this.exhibitionAnswer.idNumber = city  ? idNumber.value : '';
  }

  cancelClicked() {
    this.router.navigateByUrl('/pages/exhibitions');
  }

  changeDataProducts(index, type, value) {
    if (!this.exhibitionAnswer.products) {
      this.exhibitionAnswer.products = [];
    }
    this.exhibitionAnswer.products[index][type] = value.target.value;
  }

  okClicked() {
    const dataConfirmation = {
      rejectionCause: this.rejectionCause,
      comment: this.comment,
      statusHistory: this.exhibitionAnswer.statusHistory
    };

    console.log(dataConfirmation);
    if (this.newStatus !== 'in-progress') {
      if ((this.newStatus === 'rejected')) {
        if (!dataConfirmation.rejectionCause) {
          Swal.fire('', 'Ingresa el campo Causa Raíz para rechazadas', 'error');
          return;
        }
      }
    }
    this.exhibitionsService.updateExhibitionsStatus(this.exhibitionAnswer.id, this.newStatus, dataConfirmation)
      .then(
        () => this.router.navigateByUrl('/pages/exhibitions'),
        err => Swal.fire('Cambiando Estado', err.message || err, 'error')
      );
  }


}

