import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { PointsService } from "../../../../services/points.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { AddPointsData } from "../../../../models/points/models";
import Swal from "sweetalert2";

@Component({
  selector: "app-account-add-points",
  templateUrl: "./account-add-points.component.html",
  styleUrls: ["./account-add-points.component.scss"],
})
export class AccountAddPointsComponent implements OnInit {
  loading = false;
  accountId: string;

  addPointsForm = this.fb.group({
    points: ['', Validators.required],
    description: ['', Validators.required],
    operation: ['', Validators.required],
    grantorId: ['', Validators.required],
    expires: [false],
    expiryDatetime: [''],
  });

  constructor(
    private pointsService: PointsService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.accountId = params.get("id");
      this.loading = false;
    });
  }

  addPointsFormSubmit() {
    const formData = this.addPointsForm.value;
    const addData: AddPointsData = {
      points: formData.points,
      description: formData.description,
      creditData: {
        grantor: {
          taxId: formData.grantorId,
        },
        operation: formData.operation,
      },
    };
    if (formData.expires) {
      addData.creditData.expiryDatetime = new Date(
        formData.expiryDatetime
      ).toISOString();
    }
    this.loading = true;
    this.pointsService.addPoints(this.accountId, addData).subscribe(
      () => {
        this.loading = false;
        Swal.fire({
          icon: "success",
          text: "Puntos Otorgados",
        }).then(() => this.router.navigate([".."], { relativeTo: this.route }));
      },
      (error) => {
        console.error("AccountAddPointsComponent: calling add-points", error);
        Swal.fire({
          icon: "error",
          text:
            "No se pudieron otorgar los puntos. Por favor intente de nuevo.",
        });
      }
    );
  }
}
