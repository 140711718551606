import { Component, OnInit } from '@angular/core';
import { PointsService } from '../../../../services/points.service';
import { Router } from '@angular/router';
import { AppUserOutput } from '../../../../users-api';
import {
  AccountTypeOutput,
  AccountTypesList,
  Account,
} from '../../../../models/points/models';
import Swal from 'sweetalert2';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-accounts-edit',
  templateUrl: './accounts-edit.component.html',
  styleUrls: ['./accounts-edit.component.scss'],
})
export class AccountsEditComponent implements OnInit {
  typePage = 'create';
  loading = false;
  accountTypes: AccountTypeOutput[];
  account: Account;
  owner: AppUserOutput;
  saveEnabled = false;

  _accountTypeId = '';

  set accountTypeId(id: string) {
    this._accountTypeId = id;
    this.saveEnabled = this.owner && id !== '';
  }

  get accountTypeId() {
    return this._accountTypeId;
  }

  constructor(
    public pointsService: PointsService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.getAccountTypes();
  }

  getAccountTypes() {
    this.pointsService.listAccountTypes().subscribe(
      (res: AccountTypesList) => {
        this.loading = false;
        this.accountTypes = res.data;
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        console.error('AccountsEditComponent: getTypeAccount', error);
        this.accountTypes = [];
      }
    );
  }

  onUserSelected(user: AppUserOutput) {
    this.owner = user;
    this.saveEnabled = user && this.accountTypeId && this.accountTypeId !== '';
  }

  save() {
    const obj: any = {
      type: this.accountTypeId,
      owner: {
        id: this.owner.id,
        appId: this.owner.app_id,
        username: this.owner.username,
      },
    };
    if (this.owner.email && this.owner.email_verified) {
      obj.owner.email = this.owner.email;
    }

    this.pointsService.createAccount(obj).subscribe(
      () => {
        Swal.fire({
          icon: 'success',
          title: 'Cuenta creada',
        }).then(() => {
          Swal.close();
          this.loading = true;
          this.router.navigate(['/pages/points/accounts']);
        });
      },
      (err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          title: 'Error guardando',
          text: 'Por favor intente de nuevo'
        });
      }
    );
  }

  cancel() {
    this.router.navigate(['/pages/points/accounts']);
  }
}
