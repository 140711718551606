// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#report-container {
  position: relative;
  left: -64px;
  padding: 2em;
  margin-right: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/consolidado-puntos-pedidos/consolidado-puntos-pedidos.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AACF","sourcesContent":["#report-container {\n  position: relative;\n  left: -64px;\n  padding: 2em;\n  margin-right: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
