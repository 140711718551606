import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, InjectionToken, PLATFORM_ID, NgZone } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { HttpClientModule } from '@angular/common/http';

import { AppCommonModule } from './app-common/app-common.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './auth-guard.service';
import { BASE_PATH, ApiModule, AuthService } from './users-api';

import { environment } from '../environments/environment';
import { MainSidenavService } from './main-sidenav.service';
import { AutoIdService } from './auto-id.service';

import { UtilsService } from './services/utils.service';
import { BrandService } from './services/brand.service';
import { SalesPointsService } from './services/sales-points.service';
import { AlliesService } from './services/allies.service';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

import {
  angularFireAuthFactory,
  angularFireFunctionsFactory,
  angularFirestoreFactory,
  angularFireStorageFactory,
} from './firebase.factory';
import { RecognitionsService } from './services/recognitions.service';
import { BagsService } from './services/bags.service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DrupalUserTransferService } from './services/drupal-user-transfer.service';

export const FIREBASE_PROJECT_USERS = new InjectionToken('firebase project key injector');
export const FIREBASE_PROJECT_CHALLENGES = new InjectionToken('firebase project key injector');
export const FIREBASE_PROJECT_INVOICES = new InjectionToken('firebase project key injector');
export const FIREBASE_PROJECT_EXHIBITIONS = new InjectionToken('firebase project key injector');
export const FIREBASE_PROJECT_DATAIMPORTERS = new InjectionToken('firebase project key injector');
export const USERS_FUNCTIONS_ORIGIN = new InjectionToken('angularfire2.functions.origin');
const usersFunctionsOrigin = 'https://' + environment.appDomain;

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    AppCommonModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase.users),
    AngularFireAuthModule,
    FormsModule,
    ApiModule,
    HttpClientModule,
    PagesModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxMaterialTimepickerModule,
    CKEditorModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthGuardService,
    { provide: BASE_PATH, useValue: environment.usersApiBasePath },
    { provide: FIREBASE_PROJECT_USERS, useValue: 'users' },
    { provide: FIREBASE_PROJECT_CHALLENGES, useValue: 'challenges' },
    { provide: FIREBASE_PROJECT_INVOICES, useValue: 'challenges' },
    { provide: FIREBASE_PROJECT_EXHIBITIONS, useValue: 'challenges' },
    { provide: FIREBASE_PROJECT_DATAIMPORTERS, useValue: 'dataimporters' },
    {
      provide: 'UsersFireAuth',
      deps: [FIREBASE_PROJECT_USERS, PLATFORM_ID, NgZone],
      useFactory: angularFireAuthFactory
    },
    {
      provide: 'ChallengesFireAuth',
      deps: [FIREBASE_PROJECT_CHALLENGES, PLATFORM_ID, NgZone],
      useFactory: angularFireAuthFactory
    },
    {
      provide: 'InvoicesFireAuth',
      deps: [FIREBASE_PROJECT_INVOICES, PLATFORM_ID, NgZone],
      useFactory: angularFireAuthFactory
    },
    {
      provide: 'ExhibitionsFireAuth',
      deps: [FIREBASE_PROJECT_EXHIBITIONS, PLATFORM_ID, NgZone],
      useFactory: angularFireAuthFactory
    },
    {
      provide: 'DataImportersFireAuth',
      deps: [FIREBASE_PROJECT_DATAIMPORTERS, PLATFORM_ID, NgZone],
      useFactory: angularFireAuthFactory
    },
    // Config to call Firebase functions on same app domain, to avoid CORS
    // must configure rewrites to functions on firebase.json hosting.rewrites
    { provide: USERS_FUNCTIONS_ORIGIN, useValue: usersFunctionsOrigin },
    {
      provide: 'UsersFireFunctions',
      deps: [FIREBASE_PROJECT_USERS, PLATFORM_ID, NgZone, USERS_FUNCTIONS_ORIGIN],
      useFactory: angularFireFunctionsFactory
    },
    {
      provide: 'ChallengesFirestore',
      useFactory: angularFirestoreFactory,
      deps: [ FIREBASE_PROJECT_CHALLENGES, PLATFORM_ID, NgZone ]
    },
    {
      provide: 'InvoicesFirestore',
      useFactory: angularFirestoreFactory,
      deps: [ FIREBASE_PROJECT_INVOICES, PLATFORM_ID, NgZone ]
    },
    {
      provide: 'ExhibitionsFirestore',
      useFactory: angularFirestoreFactory,
      deps: [ FIREBASE_PROJECT_EXHIBITIONS, PLATFORM_ID, NgZone ]
    },
    {
      provide: 'DataImportersFirestore',
      useFactory: angularFirestoreFactory,
      deps: [ FIREBASE_PROJECT_DATAIMPORTERS, PLATFORM_ID, NgZone ]
    },
    {
      provide: 'ChallengesFireStorage',
      useFactory: angularFireStorageFactory,
      deps: [ FIREBASE_PROJECT_CHALLENGES, PLATFORM_ID, NgZone ]
    },
    {
      provide: 'DataImportersFireStorage',
      useFactory: angularFireStorageFactory,
      deps: [ FIREBASE_PROJECT_DATAIMPORTERS, PLATFORM_ID, NgZone ]
    },
    AuthService,
    MainSidenavService,
    AutoIdService,
    UtilsService,
    BrandService,
    SalesPointsService,
    AlliesService,
    RecognitionsService,
    BagsService,
    DrupalUserTransferService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
