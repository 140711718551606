import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import {
  AppUserOutput,
  AppUsersService,
  AppUsersList
} from '../../users-api';
import {UntypedFormControl} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {debounceTime, filter, map, distinctUntilChanged, switchMap, catchError} from 'rxjs/operators';

@Component({
  selector: 'app-app-user-selector',
  templateUrl: './app-user-selector.component.html',
  styleUrls: ['./app-user-selector.component.scss']
})
export class AppUserSelectorComponent implements OnInit {
  @Output() userSelected = new EventEmitter<AppUserOutput>();

  @Input()
  set currentUser(user: AppUserOutput) {
    this.selectedUser = user;
    this.showUser = true;
  }

  @Input() showAppSelector = true;

  @Input() currentAppId = '';

  selectedUser: AppUserOutput;
  searchBy = 'username';
  searchValue = new UntypedFormControl();
  searchResults: Observable<AppUserOutput[]>;
  searching = false;
  showUser = false;

  constructor(
    private usersService: AppUsersService,
  ) {}

  ngOnInit() {
    this.showUser = this.selectedUser ? true : false;
    this.searchResults = this.searchValue.valueChanges.pipe(
      debounceTime(1000),
      map((input: string) => input.trim()),
      filter((input: string) => input !== ''),
      distinctUntilChanged(),
      switchMap((value: string) => {
        this.searching = true;
        let condition = `${this.searchBy} sw "${value}"`;
        if (this.currentAppId && this.currentAppId !== '') {
          condition = `${condition} and app_id eq "${this.currentAppId}"`;
        }
        return this.usersService.findAppUsers('5', null, null, null, condition);
      }),
      map((ans: AppUsersList) => {
        this.searching = false;
        return ans.data;
      }),
      catchError((error: any) => {
        this.searching = false;
        console.error('AppUserSelectorComponent: finding users', error);
        return of(<AppUserOutput[]>[]);
      })
    );
  }

  onAppSelected(appId: string) {
    this.currentAppId = appId;
  }

  onUserSelected(user: AppUserOutput) {
    this.selectedUser = user;
    this.showUser = true;
    this.userSelected.emit(user);
  }

  switchToSearch() {
    this.showUser = false;
  }

}
