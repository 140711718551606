/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from "@angular/core";

// eslint-disable-next-line @typescript-eslint/naming-convention
interface routes {
  name: String;
  path?: String;
  icon?: String;
  children?: Array<any>;
}
@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor() {}
  getRoutesApplication(): Array<routes> {
    return [
      {
        name: "Home",
        path: "/home",
        icon: "home",
      },
      {
        name: "Usuarios",
        path: "/",
        children: [
          {
            name: "Empresas",
            path: "/pages/companies",
            icon: "store",
          },
          {
            name: "Aplicaciones",
            path: "/pages/apps",
            icon: "laptop",
          },
          {
            name: "Usuarios",
            path: "/pages/app-users",
            icon: "account_circle",
          },
          {
            name: "Cuentas de servicio",
            path: "/pages/service-accounts",
            icon: "receipt_long",
          },
        ],
      },
      {
        name: "Pointz",
        path: "/",
        children: [
          {
            name: "Aliados",
            path: "/pages/allies/list",
            icon: "store",
          },
          {
            name: "Canjes",
            path: "/pages/estado-canjes",
            icon: "account_circle",
          },
          {
            name: "Marcas",
            path: "/pages/brands/list",
            icon: "branding_watermark",
          },
          {
            name: "Categorias",
            path: "/pages/allies/category/list",
            icon: "category",
          },
          {
            name: "Puntos de venta",
            path: "/pages/sales-point/list",
            icon: "storefront",
          },
        ],
      },
      {
        name: "Banco de puntos",
        path: "/",
        children: [
          {
            name: "Cuentas",
            path: "/pages/points/accounts",
            icon: "people",
          },
          {
            name: "Tipos de cuentas",
            path: "/pages/points/type-accounts",
            icon: "category",
          },
        ],
      },
      {
        name: "Contenidos",
        path: "/",
        children: [
          {
            name: "CMS",
            path: "/pages/drupal",
            icon: "description"
          },
          {
            name: "CMS Antiguo",
            path: "/pages/cms",
            icon: "description"
          },
        ]
      },
      {
        name: "Retos, Facturas, Exhibiciones",
        path: "/",
        children: [
          {
            name: "Administrar Respuestas Retos",
            path: "/pages/challenges",
            icon: "radar"
          },
          {
            name: "Administrar Respuestas Facturas",
            path: "/pages/invoices",
            icon: "receipt"
          },
          {
            name: "Administrar Respuestas Exhibiciones",
            path: "/pages/exhibitions",
            icon: "receipt"
          }
        ]
      },
      {
        name: "Importadores",
        path: "/",
        children: [
          {
            name: "Importadores",
            path: "/pages/imports",
            icon: "cloud_upload",
          }
        ]
      },
      {
        name: "Reportes",
        path: "/",
        children: [
          {
            name: "Consolidado Transacciones y Pedidos",
            path: "/pages/consolidado-puntos-pedidos",
            icon: "local_shipping",
          },
          {
            name: "Consolidado Facturas",
            path: "/pages/invoices-report",
            icon: "receipt",
          },
          {
            name: "Consolidado Exhibiciones",
            path: "/pages/exhibitions-report",
            icon: "receipt",
          },
          {
            name: "Consolidado Retos",
            path: "/pages/challenges-report",
            icon: "radar",
          }
        ]
      },
      {
        name: "Reconocimientos",
        path: "/",
        children: [
          {
            name: "Listado",
            path: "/pages/recognitions",
            icon: "emoji_events",
          }
        ]
      },
      {
        name: "Indicadores",
        path: "/",
        children: [
          {
            name: "Categorias",
            path: "/pages/categories/list",
            icon: "table_chart_view",
          },
          {
            name: "Indicadores",
            path: "/pages/indicators/list",
            icon: "insights",
          },
          {
            name: "Secciones",
            path: "/pages/sections/list",
            icon: "receipt_long",
          }
        ]
      },
      {
        name: "Bolsas",
        path: "/",
        children: [
          {
            name: "Listado Bolsas",
            path: "/pages/bags",
            icon: "request_quote",
          }
        ]
      },
      {
        name: "Exhibiciones",
        path: "/",
        children: [
          {
            name: "Tablas",
            path: "/pages/exhibition/tables",
            icon: "table",
          },
          {
            name: "Formularios",
            path: "/pages/exhibition/forms",
            icon: "article",
          }
        ]
      }
    ];
  }
}
