import { PERMISSIONS } from './../../../../services/permissions/permissions.service';
import { Component, OnInit  } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ImportService } from '../../servers/import.service';
import { StorageService } from '../../../../services/storage.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {formatDate} from '@angular/common';


@Component({
  selector: 'app-create-import',
  templateUrl: './create-import.component.html',
  styleUrls: ['./create-import.component.scss']
})
export class CreateImportComponent implements OnInit {
  PERMISSIONS = PERMISSIONS;
  formImport: UntypedFormGroup;
  fileName: string | undefined;
  file: File;
  type: string | undefined;
  appId: string | undefined;
  format: string | undefined;
  allowedAppIds:string[];
  allowAllAppIds:boolean
  constructor(
    private importador: ImportService,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CreateImportComponent>
  ) { }

  async ngOnInit() {
    this.initAllowedIds();
    await this.importador.configFirebase();
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];
  }

  async crearImportacion() {

    try {
      const account = await this.storageService.get('account');
      const when = formatDate(new Date(), 'yyyy-MM-dd-HHmmss', 'en-US');
      const nameFile = `${account.username}-${this.type}-${when}`;
      const ext = this.file.name.split('.').pop();
      const ref =  `imports/${nameFile}.${ext}`;
      const url = await this.importador.importFile(this.file, ref);

      const data: any = {
        file: `gs://${url.ref.bucket}/${url.ref.fullPath}`,
        type: this.type,
        date_time: new Date(),
        job_created: false,
        state: 'JOB_STATE_PENDING',
        uid: account.id,
        username: account.username,
        email: account.email || '',
      };

      if (this.requiresAppId && this.appId) {
        data.appId = this.appId;
      }

      if (this.requiresFormat && this.format) {
        data.format = this.format;
      }

      await this.importador.createImportation(data);

      this.snackBar.open('Se creó con éxito la importación', 'OK');
      this.dialogRef.close(true);

    } catch (error) {
      console.error(error);
      this.snackBar.open(
        `Error de importación: ${error.message || error}`,
        'OK'
      );
    }
    this.file = null;
    this.fileName = null;
    this.type = null;
  }

  appIdSelected(appId: string) {
    this.appId = appId;
  }

  get isConfigured() {
    if (!this.type) {
      return false;
    }
    if (this.requiresAppId && !this.appId) {
      return false;
    }
    if (this.requiresFormat && !this.format) {
      return false;
    }
    return this.fileName && this.fileName !== '';
  }

  get requiresAppId() {
    return this.type
    && [
      'kpis',
      'bank_transactions',
      'users_by_appid',
    ].includes(this.type);
  }

  get requiresFormat() {
    return this.type
    && [
      'kpis',
      'bank_transactions',
      'central_deliveries_updater',
      'users_by_appid',
    ].includes(this.type);
  }

}
