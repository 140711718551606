import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {ProgramsService, ProgramFull, ProgramsList} from '../users-api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgramsUtilService {

  constructor(
    private programsService: ProgramsService,
    private storageService: StorageService,
    private http: HttpClient
  ) { }

  getCachedPrograms(): ProgramFull[] {
    const cache = this.storageService.get('ProgramsUtilService:programs');
    if (cache && cache.expiryTimestamp > Date.now()) {
      return cache.data;
    }
    return null;
  }

  setCachedPrograms(programs: ProgramFull[]): void {
    const cache = {
      expiryTimestamp: Date.now() + 5 * 60000,
      data: programs,
    };
    this.storageService.set('ProgramsUtilService:programs', cache);
  }

  async getFromAppIds(appId: string): Promise<ProgramFull[]> {
    try {
      const proms = this.getProgramFromApp(appId).toPromise();
      const programs = Promise.all((await proms).data);
      return programs;
    } catch (error) {
      const emsg = error.error ? JSON.stringify(error.error) : error.message;
      throw new Error(emsg);
    }
  }

  async getPrograms(appId: string, useCache = false): Promise<ProgramFull[]> {
    if (useCache) {
      const cachedApps = this.getCachedPrograms();
      if (cachedApps) {
        return cachedApps;
      }
    }
    try {
      const list = await this.getProgramFromApp(appId).toPromise();
      this.setCachedPrograms(list.data);
      return list.data;
    } catch (error) {
      // Si el usuario no tiene permiso de ver todas las apps le da FORBIDDEN
      // en ese caso se listan solo las aplicaciones autorizadas en el perfil
      const emsg = error.error ? JSON.stringify(error.error) : error.message;
      throw new Error(emsg);
    }
  }

  getProgramFromApp(appId: string): Observable<ProgramsList>{
    return this.http.get<ProgramsList>(`${environment.usersApiBasePath}/apps/${appId}/programs`);
  }
  getProgram(appId: string,programId): Observable<any>{
    return this.http.get<any>(`${environment.usersApiBasePath}/apps/${appId}/programs/${programId}`);
  }
  putProgram(appId: string,programId, data){/* : Observable<ProgramsList> */
  const headers = new HttpHeaders ({ 'Content-Type':'application/json'});
    return this.http.put<any>(`${environment.usersApiBasePath}/companies/${appId}/programs/${programId}`,data, {headers});
  }



}
