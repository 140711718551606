import { Component, OnInit } from "@angular/core";
import { ServiceAccountsService } from "../../../services/service-accounts.service";
import {
  serviceAccountsInterface,
  DataEntity as ServiceAccountInterface,
} from "../../../models/users/service-accounts.model";
import { Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
@Component({
  selector: "app-service-accounts",
  templateUrl: "./service-accounts.component.html",
  styleUrls: ["./service-accounts.component.scss"],
})
export class ServiceAccountsComponent implements OnInit {
  constructor(
    public serviceAccountService: ServiceAccountsService,
    public router: Router
  ) {}
  serviceAccounts: ServiceAccountInterface[] = [];
  title: string = "Cuentas de servicio";
  loading: Boolean = false;
  dataSource: MatTableDataSource<ServiceAccountInterface>;
  displayedColumns: string[] = ["Id", "apps", "enabled", "edit"];
  ngOnInit() {
    this.loading = true;
    this.listServiceAccounts();
  }

  listServiceAccounts() {
    this.serviceAccountService.getServiceAccounts().subscribe((res) => {
      this.serviceAccounts = res.data;
      this.dataSource = new MatTableDataSource(this.serviceAccounts);
      this.loading = false;
    });
  }
  redirect() {
    this.router.navigate(["/pages/service-accounts/create"]);
  }
  redirectById(id) {
    this.router.navigate([`/pages/service-accounts/edit/${id}`]);
  }
  searchAccountService(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }
}
