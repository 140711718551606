import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable ,  Observer } from 'rxjs';

import { MainSidenavService } from '../../main-sidenav.service';
import { CompaniesService, CompaniesList, Company } from '../../users-api';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  providers: [
    CompaniesService,
  ]
})
export class CompaniesComponent implements OnInit {
  loading = false;
  displayedColumns = ['id', 'name', 'id_doc', 'country', 'created_at', 'updated_at', 'edit'];
  dataSource = new CompaniesDataSource();
  pageSize = 100;
  pagerSteps = [100, 200, 500, 1000];
  nextCursor: string;
  prevCursor: string;
  firstCursor: string;
  hoveredRow: any;

  constructor(
    private snackBar: MatSnackBar,
    public mainSidenavService: MainSidenavService,
    private router : Router,
    private companiesApi: CompaniesService,
  ) { }

  ngOnInit() {
    this.updateData();
  }

  updateData(nextCursor?: string, prevCursor?: string, firstCursor?: string) {
    this.loading = true;
    this.companiesApi.findCompanies(this.pageSize.toString(), nextCursor, prevCursor, firstCursor)
      .subscribe(
        (list: CompaniesList) => {
          this.loading = false;
          this.dataSource.refreshData(list.data);
          if ('next_page_cursor' in list) {
            this.nextCursor = list.next_page_cursor;
          }	
          if ('prev_page_cursor' in list) {
            this.prevCursor = list.prev_page_cursor;
          }
          if ('first_page_cursor' in list) {
            this.firstCursor = list.first_page_cursor;
          }
        },
        error => {
          this.loading = false;
          console.error('Error on companiesApi.findCompany', error);
          let msg = 'Error de comunicación';
          if (error.status >= 400 && error.status < 500) {
            msg = 'Acceso denegado';
          }
          this.snackBar.open(msg, "OK");
        }
      );
  }

  itemHasCreatedAt(item) {
    return ('created_at' in item);
  }

  itemHasUpdatedAt(item) {
    return ('updated_at' in item);
  }

  loadFirstPage() {
    this.updateData();
    return false;
  }

  loadPrevPage() {
    if (this.prevCursor && this.firstCursor) {
      this.updateData(undefined, this.prevCursor, this.firstCursor);
    }
    return false;
  }

  loadNextPage() {
    if (this.nextCursor && this.firstCursor) {
      this.updateData(this.nextCursor, undefined, this.firstCursor);
    }
    return false;
  }

  pageSizeCtrlChanged() {
    this.updateData();
    return false;
  }

  onEdit(company: Company) {
    this.router.navigate(['pages', 'companies', company.id]);
    return false;
  }

  onCreate() {
    this.router.navigateByUrl('/pages/companies/new');
    return false;
  }

  onCompanies(company: Company) {
    this.router.navigate(['pages', 'companies', company.id, 'programs']);
    return false;
  }

  getRowBackgroundColor(row) {
    return row == this.hoveredRow ? '#F5F5F5' : 'transparent';
  }

}

export class CompaniesDataSource extends DataSource<Company> {
  observer: Observer<Company[]>;

  refreshData(data: Company[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<Company[]> {
    return Observable.create((observer) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}
