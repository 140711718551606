import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Observer } from 'rxjs';
import { ENTER } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';

import { MainSidenavService } from '../../main-sidenav.service';

import {
  AppUsersService,
  AppUsersList,
  AppUserOutput,
} from '../../users-api';

export class AppUsersDataSource extends DataSource<AppUserOutput> {
  observer: Observer<AppUserOutput[]>;

  refreshData(data: AppUserOutput[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<AppUserOutput[]> {
    return Observable.create((observer: Observer<AppUserOutput[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}

@Component({
  selector: 'app-app-users',
  templateUrl: './app-users.component.html',
  styleUrls: ['./app-users.component.scss'],
  providers: [AppUsersService],
})
export class AppUsersComponent implements OnInit {
  displayedColumns = ['info'];
  pageSize = 100;
  loading = false;
  listDataSource = new AppUsersDataSource();
  hoveredRow: any;
  pagerSteps = [100, 200, 500, 1000];
  prevCursor: string;
  nextCursor: string;
  firstCursor: string;

  filterSelected = '';
  filter = '';
  errorMessage = '';
  currentFilter = '';
  appIdFilter = '';

  constructor(
    private usersApi: AppUsersService,
    private snackBar: MatSnackBar,
    public mainSidenavService: MainSidenavService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loading = false;
    // this.updateData();
    this.filterSelected = 'email';
  }

  updateData(nextCursor?: string, prevCursor?: string, firstCursor?: string) {
    this.loading = true;
    let filter = null;
    // Update filter
    // si no hay un tipo de filtro pero si hay value en el campo de busqueda
    if (this.filter !== '' && this.filterSelected === '') {
      this.loading = false;
      return (this.errorMessage = 'Selecciona un filtro');
    }
    // si hay filtro pero no value en el campo de busqueda
    if (this.appIdFilter === '' && this.filter === '' && this.filterSelected !== '') {
      this.loading = false;
      return (this.errorMessage = 'Ingresa un valor');
    }
    this.errorMessage = '';
    if (this.filterSelected !== '' && this.filter !== '') {
      const valueFilter = this.filter ? this.filter.trim() : undefined;
      if (valueFilter) {
        // WARNING: filter value must be in double quotes ""
        filter = `${this.filterSelected} sw "${valueFilter}"`;
      }
    }
    if (this.appIdFilter !== '') {
      const appFilterOp = `app_id eq "${this.appIdFilter}"`;
      filter = filter ? `${filter} and ${appFilterOp}` : appFilterOp;
    }
    // If new filter cursors are no longer valid
    if (filter !== this.currentFilter) {
      nextCursor = undefined;
      prevCursor = undefined;
      this.currentFilter = filter;
    }
    this.usersApi
      .findAppUsers(
        // this.pageSize.toString(),
        '0',
        nextCursor,
        prevCursor,
        firstCursor,
        filter
      )
      .subscribe(
        (list: AppUsersList) => {
          this.loading = false;
          this.listDataSource.refreshData(list.data);
          if ('next_page_cursor' in list) {
            this.nextCursor = list.next_page_cursor;
          }
          if ('prev_page_cursor' in list) {
            this.prevCursor = list.prev_page_cursor;
          }
          if ('first_page_cursor' in list) {
            this.firstCursor = list.first_page_cursor;
          }
        },
        (error) => {
          this.loading = false;
          console.error('Error on usersApi.findAppUsers', error);
          this.snackBar.open('Error de comunicación.', 'OK');
        }
      );
  }

  loadFirstPage() {
    this.updateData();
    return false;
  }

  loadPrevPage() {
    if (this.prevCursor && this.firstCursor) {
      this.updateData(undefined, this.prevCursor, this.firstCursor);
    }
    return false;
  }

  loadNextPage() {
    if (this.nextCursor && this.firstCursor) {
      this.updateData(this.nextCursor, undefined, this.firstCursor);
    }
    return false;
  }

  pageSizeCtrlChanged() {
    this.updateData();
    return false;
  }

  onCreateUser() {
    this.router.navigateByUrl('/pages/app-users/new-user');
    return false;
  }

  onEditUser(user) {
    this.router.navigate(['pages', 'app-users', user.id]);
    return false;
  }

  onFilterKeyUp(event: KeyboardEvent) {
    // this[type] = (<HTMLInputElement>event.target).value;
    if (event.keyCode === ENTER) {
      this.updateData();
    }
  }

  clearFiltersAndUpdate() {
    this.filter = '';
    this.filterSelected = '';
    this.errorMessage = '';
    this.appIdFilter = '';
    this.updateData();
  }

  appIdFilterSelected(value: string) {
    if (this.appIdFilter !== value) {
      this.appIdFilter = value;
    }
  }
}
