import { Injectable, Inject } from '@angular/core';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import moment from 'moment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AngularFireFunctions } from '@angular/fire/functions';
import lodash from 'lodash';
import Swal from 'sweetalert2';

const helperJWT = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: Observable<any>;
  headers: HttpHeaders;
  emailSent: boolean;
  email: string;

  public fbTokenJWT: string;

  constructor(
    @Inject('UsersFireAuth') private afAuth: AngularFireAuth,
    @Inject('UsersFireFunctions') private usersFunctions: AngularFireFunctions,
    private router: Router,
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('X-Skip-Interceptor', '');
  }

  async checkAuthState() {
    return this.afAuth.auth.getRedirectResult();
  }

  public googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ hd: 'gluky.com' });
    return this.oauthLogin(provider);
  }

  public logOut() {
    this.afAuth.auth.signOut();
    this.storageService.deleteAll();
    this.router.navigate(['login']);
  }

  getAccessFromUsers(fbTokenJWT: string): Promise<any> {
    const { headers } = this;
    const { app_id } = environment.gluky;
    const apikey = environment.apikey;

    return new Promise((resolve, reject) =>
      this.fetchAccessJwt(app_id, apikey, headers, fbTokenJWT).subscribe(
        (res: { match: boolean; gn_token: string; users?: []; account?: any; profile?: any }) => {
          if (res.users && res.users.length > 0) {
            resolve(res.users);
          }
          if (res.match && res.gn_token) {
            this.storageService.set('account', res.account);
            this.storageService.set('profile', res.profile ? res.profile.data : null);
            resolve(res.gn_token);
          }
        },
        (err) => {
          reject(err);
        }
      )
    );
  }

  fetchAccessJwt(app_id: string, apikey: string, headers: HttpHeaders, fbTokenJWT: string) {
    const params: any = {
      app_id,
      app_jwt: fbTokenJWT,
    };
    const username = this.storageService.get('user_gnx');
    if (username) {
      params.username = username;
    }

    return this.http.post(
      `${environment.usersApiBasePath}/auth/access-jwt?key=${apikey}`,
      {
        ...params,
      },
      {
        headers,
      }
    );
  }

  async refreshToken() {
    try {
      const fbtokenJWT = await this.getIdToken();
      const newToken = await this.getAccessFromUsers(fbtokenJWT);
      return newToken;
    } catch (err) {
      throw new Error(`AuthService: Refreshing GNJwt: ${err.message || JSON.stringify(err)}`);
    }
  }

  verifyTimeStamp(token: string) {
    const tokenTimeStamp = helperJWT.decodeToken(token).exp;
    const actualDate = moment().unix();
    return tokenTimeStamp <= actualDate;
  }

  async getIdToken(force = false) {
    const user: firebase.User = await this.afAuth.user
      .pipe(first())
      .toPromise();
    return user.getIdToken(force);
  }

  getUser() {
    return this.afAuth.user;
  }

  async getCredentials(baseUrl: string) {
    const token = await this.getGNAccessToken();
    const credentials: any = {
      token,
      base_url: baseUrl,
      api_key: environment.apikey,
    };

    const userGnx = this.storageService.get('user_gnx');
    if (userGnx) {
      credentials.user = userGnx;
    }
    return credentials;
  }

  async getGNAccessTokenNoCache() {
    const token = await this.refreshToken();
    if (typeof token === 'string') {
      this.storageService.set('gn_access_token', token);
    }
    return token;
  }

  async getGNAccessToken() {
    let token = this.storageService.get('gn_access_token');
    let expiredToken = true;
    if (token) {
      try {
        expiredToken = this.verifyTimeStamp(token);
      } catch (e) {
        console.warn(`While verifying gn_jwt timesamp: ${e}`);
        expiredToken = true;
      }
    }
    if (expiredToken) {
      token = await this.getGNAccessTokenNoCache();
    }
    return token;
  }

  setUsername(username: string) {
    this.storageService.set('user_gnx', username);
  }

  async remoteUserValid(remoteUser: firebase.User): Promise<boolean> {
    const usersToken = await this.getIdToken();
    const remoteToken = await remoteUser.getIdToken();
    const usersData = helperJWT.decodeToken(usersToken);
    const remoteData = helperJWT.decodeToken(remoteToken);
    if (!usersData
      || !remoteData
      || !usersData.gluky
      || !remoteData.gluky) {
      return false;
    }

    const toCheck = [
      'uid',
      'app_id',
      'username',
      'roles',
      'programs',
      'profile',
    ];
    const match = toCheck.reduce((prevMatch, prop) => {
      if (!prevMatch) {
        return false;
      }
      if (prop in usersData.gluky) {
        if (!(prop in remoteData.gluky)) {
          return false;
        }
        return lodash.isEqual(usersData.gluky[prop], remoteData.gluky[prop]);
      }
      return prevMatch;
    }, true);
    return match;
  }

  async getRemoteFirebaseUser(fireAuth: AngularFireAuth, projectKey: string): Promise<firebase.User> {
    const remoteUser = await fireAuth.user.pipe(first()).toPromise();
    if (remoteUser && await this.remoteUserValid(remoteUser)) {
      return remoteUser;
    }
    const remoteToken = this.usersFunctions.httpsCallable('remoteFirebaseCustomToken');
    const ans = await remoteToken({
      remoteProjectId: projectKey
    }).pipe(first()).toPromise();
    if (!ans.customToken) {
      throw new Error(`Could not get ${projectKey} custom token`);
    }
    const remoteCred = await fireAuth.auth.signInWithCustomToken(ans.customToken);
    return remoteCred.user;
  }

  private async oauthLogin(provider: firebase.auth.AuthProvider) {
    return this.afAuth.auth.signInWithPopup(provider);
  }

    // Auth logic to run auth providers
    async SignInEmailAndPassword(values: { email: string }) {
      const URL_VALIDATION =`${window.location.origin}/Email_Validation`
      try {
        const actionCodeSettings = {
          url: URL_VALIDATION,
          handleCodeInApp: true

        };

        const { email } = values;
        this.email = email;
        const idTokenResult: any = await this.afAuth.auth.sendSignInLinkToEmail(email, actionCodeSettings).then(result => {
          window.localStorage.setItem('emailForSignIn', this.email);
          this.emailSent = true;
          Swal.fire('Correo Enviado', 'Se ha enviado un correo para que puedas iniciar sesión en la plataforma.', 'success');
        }).catch((error: any) => {
          switch (error.code) {
            case 'auth/invalid-email':
              Swal.fire('Correo Invalido', 'Ingrese un correo electrónico válido.', 'error');
              break;
            default:
              console.error("error catch sendSignInLinkToEmail", error, error.code, error.message)
              break;
          }
        });
      } catch (error) {
        console.log("error login", error);
      }
    }

    async getFirebaseAuth() {
      try {
        const tokenFirebase = await window.localStorage.getItem('tokenFirebase');
        console.log("tokenFirebase", tokenFirebase);
        const newToken = await this.getAccessFromUsers(tokenFirebase);
        console.log("newToken", newToken);
        if (newToken) {
          await localStorage.setItem("gnx-token", newToken);
         // await this.setUser();
          this.router.navigate(['pages','home']);
        } else {
          window.localStorage.clear();
          localStorage.clear();
          this.logOut();
          this.router.navigate(['login']);
        }
      } catch (error) {
        console.log('error getFirebase auth', error);
        window.localStorage.clear();
        localStorage.clear();
        this.logOut();
        Swal.fire('Upss...', 'No se ha iniciado sesión, verifica que tengas acceso al programa', 'warning');
        this.router.navigate(['login']);
      }
    }

    async confirmSignIn(url) {
      try {
        console.log("url", url);
        if (this.afAuth.auth.isSignInWithEmailLink(url)) {
          let email = window.localStorage.getItem('emailForSignIn');
          console.log("email", email);
          // If missing email, prompt user for it
          if (!email) {
            console.error('No email')
            return
           /*  this.error = false;
            return this.error; */
          }
          // Signin user and remove the email localStorage
          const result = await this.afAuth.auth.signInWithEmailLink(email, url);
          console.log("result auth", result, result.user);
          var idTokenResult = await result.user.getIdTokenResult(true);
          if (idTokenResult && idTokenResult.token) {
            console.log('firebase token', idTokenResult.token);
            window.localStorage.setItem('tokenFirebase', idTokenResult.token);
            window.localStorage.removeItem('emailForSignIn');
            return result;
          } else {
            throw 'error';
          }
        } else {
          throw new Error("error auth");
        }
      } catch (err) {
        console.log("error", err);
        return
        /* this.errorMessage = err.message;
        return this.errorMessage; */
      }
    }

}
