import { PERMISSIONS } from './../../../services/permissions/permissions.service';
import {switchMap} from 'rxjs/operators';

import { Observable ,  Observer } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { MainSidenavService } from '../../../main-sidenav.service';
import {
  CompaniesService,
  Company,
  ProgramsService,
  ProgramsList,
  ProgramFull
} from '../../../users-api';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
  providers: [ CompaniesService, ProgramsService ]
})
export class ProgramsComponent implements OnInit {
  loading = false;
  displayedColumns = [
    'program_id',
    'name',
    'country',
    'points_account_type',
    'created_at',
    'updated_at',
    'edit'
  ];
  dataSource = new ProgramsDataSource();
  pageSize = 100;
  pagerSteps = [100, 200, 500, 1000];
  nextCursor: string;
  prevCursor: string;
  firstCursor: string;
  hoveredRow: any;
  company: Company;

  PERMISSIONS = PERMISSIONS;

  constructor(
    private snackBar: MatSnackBar,
    public mainSidenavService: MainSidenavService,
    private router: Router,
    private route: ActivatedRoute,
    private companiesApi: CompaniesService,
    private programsApi: ProgramsService,
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      this.companiesApi.retrieveCompany(params.get('companyId'))
    )).subscribe((company: Company) => {
      this.loading = false;
      this.company = company;
      this.updateData();
    }, error  => {
      this.loading = false;
      console.error('Reading company', error);
      this.snackBar.open('Error reading company: ' + error);
    });
  }

  updateData(nextCursor?: string, prevCursor?: string, firstCursor?: string) {
    this.loading = true;
    this.programsApi.findCompanyPrograms(this.company.id, this.pageSize.toString(), nextCursor, prevCursor, firstCursor)
      .subscribe(
        (list: ProgramsList) => {
          this.loading = false;
          this.dataSource.refreshData(list.data);
          if ('next_page_cursor' in list) {
            this.nextCursor = list.next_page_cursor;
          }
          if ('prev_page_cursor' in list) {
            this.prevCursor = list.prev_page_cursor;
          }
          if ('first_page_cursor' in list) {
            this.firstCursor = list.first_page_cursor;
          }
        },
        error => {
          this.loading = false;
          console.error('Error on programsApi.findCompanyPrograms', error);
          let msg = 'Error de comunicación';
          if (error.status >= 400 && error.status < 500) {
            msg = 'Acceso denegado';
          }
          this.snackBar.open(msg, "OK");
        }
      );
  }

  itemHasCreatedAt(item) {
    return ('created_at' in item);
  }

  itemHasUpdatedAt(item) {
    return ('updated_at' in item);
  }

  loadFirstPage() {
    this.updateData();
    return false;
  }

  loadPrevPage() {
    if (this.prevCursor && this.firstCursor) {
      this.updateData(undefined, this.prevCursor, this.firstCursor);
    }
    return false;
  }

  loadNextPage() {
    if (this.nextCursor && this.firstCursor) {
      this.updateData(this.nextCursor, undefined, this.firstCursor);
    }
    return false;
  }

  pageSizeCtrlChanged() {
    this.updateData();
    return false;
  }

  onEdit(program: ProgramFull) {
    this.router.navigate(['pages', 'companies', program.company_id, 'programs', program.program_id]);
    return false;
  }

  onCreate() {
    this.router.navigate(['pages', 'companies', this.company.id, 'programs', 'new']);
    return false;
  }

  getRowBackgroundColor(row) {
    return row == this.hoveredRow ? '#F5F5F5' : 'transparent';
  }

}

export class ProgramsDataSource extends DataSource<ProgramFull> {
  observer: Observer<ProgramFull[]>;

  refreshData(data: ProgramFull[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<ProgramFull[]> {
    return Observable.create((observer) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}
