import { CloudinaryModule } from '@cloudinary/ng';
/* eslint-disable @typescript-eslint/naming-convention */
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDatepickerModule} from '@angular/material/datepicker';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AppCommonModule } from '../app-common/app-common.module';
import { PagesRoutingModule } from './pages-routing.module';
import { AppsComponent } from './apps/apps.component';
import { AppUsersComponent } from './app-users/app-users.component';
import { AppsEditorComponent } from './apps/apps-editor/apps-editor.component';
import { AppUsersEditorComponent } from './app-users/app-users-editor/app-users-editor.component';
import { AppsViewerComponent } from './apps/apps-viewer/apps-viewer.component';
import { AppsAddComponent } from './apps/apps-add/apps-add.component';
import { AppUsersViewerComponent } from './app-users/app-users-viewer/app-users-viewer.component';
import { AppUsersAddComponent } from './app-users/app-users-add/app-users-add.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompaniesAddComponent } from './companies/companies-add/companies-add.component';
import { CompaniesEditorComponent } from './companies/companies-editor/companies-editor.component';
import { CompaniesViewerComponent } from './companies/companies-viewer/companies-viewer.component';
import { ProgramsComponent } from './companies/programs/programs.component';
import { ProgramsAddComponent } from './companies/programs/programs-add/programs-add.component';
import { ProgramsEditorComponent } from './companies/programs/programs-editor/programs-editor.component';
import { ProgramsEditComponent } from './companies/programs/programs-edit/programs-edit.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServerHttpInterceptor } from '../services/server-http.interceptor';
import { BrandsEditComponent } from './pointz/brands-edit/brands-edit.component';
import { BrandsListComponent } from './pointz/brands-list/brands-list.component';
import { AlliesEditComponent } from './pointz/allies-edit/allies-edit.component';
import { AlliesListComponent } from './pointz/allies-list/allies-list.component';
import { SalesPointsEditComponent } from './pointz/sales-points-edit/sales-points-edit.component';
import { SalesPointsListComponent } from './pointz/sales-points-list/sales-points-list.component';
import { CanjesComponent } from './pointz/canjes/canjes.component';
import { environment } from '../../environments/environment';
//import { CloudinaryModule } from '@cloudinary/angular-5.x';

import { CloudinaryService } from '../services/cloudinary.service';
import { Cloudinary as CloudinaryCore } from 'cloudinary-core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ContainerModule } from './container/container.module';
import { AccountsComponent } from './points/components/accounts/accounts.component';
import { AccountsEditComponent } from './points/components/accounts-edit/accounts-edit.component';
import { TypeAccountsComponent } from './points/components/type-accounts/type-accounts.component';
import { TypeAccountsEditComponent } from './points/components/type-accounts-edit/type-accounts-edit.component';
import { ServiceAccountsComponent } from './service-accounts/service-accounts-list/service-accounts.component';
import { ServiceAccountsEditComponent } from './service-accounts/service-accounts-edit/service-accounts-edit.component';
import { AgmCoreModule } from '@agm/core';
import { HomeComponent } from './home/home.component';
import { AccountDetailComponent } from './points/components/account-detail/account-detail.component';
import { AccountAddPointsComponent } from './points/components/account-add-points/account-add-points.component';
import { CmsStrapiComponent } from './cms-strapi/cms-strapi.component';
import { CmsDrupalComponent } from './cms-drupal/cms-drupal.component';

import { ChallengesComponent } from './answers-challenges/challenges.component';
import { ChallengesEditComponent } from './answers-challenges/challenges-edit/challenges-edit.component';
import { ImportsComponent } from './imports/imports.component';
import { CreateImportComponent } from './imports/components/create-import/create-import.component';
import { ConsolidadoPuntosPedidosComponent } from './consolidado-puntos-pedidos/consolidado-puntos-pedidos.component';
import { InvoicesComponent } from './answers-invoices/invoices.component';
import { InvoicesEditComponent } from './answers-invoices/invoices-edit/invoices-edit.component';
import { InvocesReportComponent } from './invoces-report/invoces-report.component';
import { ChallengesReportComponent } from './challenges-report/challenges-report.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RecognitionsComponent } from './recognitions/recognitions.component';
import { RecognitionsFormComponent } from './recognitions/recognitions-form/recognitions-form.component';
import { AnswersExhibicionesComponent } from './answers-exhibiciones/answers-exhibiciones.component';
import { ExhibicionesEditComponent } from './answers-exhibiciones/exhibiciones-edit/exhibiciones-edit.component';
import { ExhibitionsReportComponent } from './exhibitions-report/exhibitions-report.component';
import { LimitsRedemptionComponent } from './limits-redemption/limits-redemption.component';
import { CategoryAddComponent } from './indicators/categories/category-add/category-add.component';
import { CategoryListComponent } from './indicators/categories/category-list/category-list.component';
import { IndicatorAddComponent } from './indicators/indicators/indicator-add/indicator-add.component';
import { IndicatorListComponent } from './indicators/indicators/indicator-list/indicator-list.component';
import { LockRedemptionComponent } from './apps/lock-redemption/lock-redemption.component';
import { CategoriesRoutingModule } from './pointz/categories/categories-routing.module';
import { BagsComponent } from './bags/bags.component';
import { BagsFormComponent } from './bags/bags-form/bags-form.component';
import { BagTransationFormComponent } from './bags/bag-transation-form/bag-transation-form.component';
import { BagTransactionComponent } from './bags/bag-transaction/bag-transaction.component';
import { SectionsAddComponent } from './indicators/sections/sections-add/sections-add.component';
import { SectionsListComponent } from './indicators/sections/sections-list/sections-list.component';
import { MatTableModule } from '@angular/material/table';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ValidationComponent } from './validation/validation.component';
import { ExhibitionsModule } from './exhibitions/exhibitions.module';

/* export const cloudinary = {
  Cloudinary: CloudinaryCore,
}; */
@NgModule({
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatGridListModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    PagesRoutingModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    CloudinaryModule,//.forRoot(cloudinary, environment.cloudinary_config),
    ContainerModule,
    AutocompleteLibModule,
    CKEditorModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmApiKey || environment.apikey,
      libraries: ['geometry', 'places'],
    }),
    CategoriesRoutingModule,
    ExhibitionsModule
  ],
  exports: [],
  declarations: [
    AppsComponent,
    AppUsersComponent,
    AppsEditorComponent,
    AppUsersEditorComponent,
    AppsViewerComponent,
    AppsAddComponent,
    AppUsersViewerComponent,
    AppUsersAddComponent,
    CompaniesComponent,
    CompaniesAddComponent,
    CompaniesEditorComponent,
    CompaniesViewerComponent,
    ProgramsComponent,
    ProgramsAddComponent,
    ProgramsEditorComponent,
    ProgramsEditComponent,
    BrandsEditComponent,
    BrandsListComponent,
    AlliesEditComponent,
    AlliesListComponent,
    SalesPointsEditComponent,
    SalesPointsListComponent,
    CanjesComponent,
    AccountsComponent,
    AccountsEditComponent,
    TypeAccountsComponent,
    TypeAccountsEditComponent,
    ServiceAccountsComponent,
    ServiceAccountsEditComponent,
    HomeComponent,
    AccountDetailComponent,
    AccountAddPointsComponent,
    CmsStrapiComponent,
    CmsDrupalComponent,
    ChallengesComponent,
    ChallengesEditComponent,
    ImportsComponent,
    CreateImportComponent,
    ConsolidadoPuntosPedidosComponent,
    InvoicesComponent,
    InvoicesEditComponent,
    InvocesReportComponent,
    ChallengesReportComponent,
    RecognitionsComponent,
    RecognitionsFormComponent,
    AnswersExhibicionesComponent,
    ExhibicionesEditComponent,
    ExhibitionsReportComponent,
    LimitsRedemptionComponent,
    CategoryAddComponent,
    CategoryListComponent,
    IndicatorAddComponent,
    IndicatorListComponent,
    LockRedemptionComponent,
    BagsComponent,
    BagsFormComponent,
    BagTransationFormComponent,
    BagTransactionComponent,
    SectionsAddComponent,
    SectionsListComponent,
    ValidationComponent
  ],
 /*  entryComponents:[
    CreateImportComponent,
  ], */
  providers: [
    CloudinaryService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerHttpInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule {}
