/* eslint-disable max-len */
import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { BagTransactions, Transaction, OPTIONS_TYPES_GENERALES, OPTIONS_TYPESMONT} from '../../../models/bags/bags-model';
import { BagsService } from '../../../services/bags.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { StorageService } from '../../../services/storage.service';
import {switchMap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

export class BagTransactionDataSource extends DataSource<Transaction> {
  observer: Observer<Transaction[]>;

  refreshData(data: Transaction[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<Transaction[]> {
    return Observable.create((observer: Observer<Transaction[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}

@Component({
  selector: 'app-bag-transaction',
  templateUrl: './bag-transaction.component.html',
  styleUrls: ['./bag-transaction.component.scss']
})
export class BagTransactionComponent implements OnInit {

  dataSource = new BagTransactionDataSource();
  displayedColumns = ['transaction_type','observations','exchange_data_delivery_globalid','exchange_data_total_price','credit_data_invoice_id','credit_data_invoice_date', 'datetime','type_motion','value_currency_code','value_cents',];
  loading = false;
  hoveredRow: any;
  allowedCompanyIds: string[];
  allowAllCompanyIds = false;
  data;
  bagId: string;
  bag: any;
  constructor(
    private bagsService: BagsService,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) { }

  async ngOnInit() {
    await this.initAllowedIds();

    this.route.paramMap.pipe(switchMap((params: ParamMap) => {
      this.bagId = params.get('id');
      return this.bagsService.getTransaction(this.bagId);
    })).subscribe((res: BagTransactions) => {
      this.data = res.data;
      this.dataSource = this.data;
      this.loading = false;
    }, error  => {
      console.error('Reading bag', error);
      this.snackBar.open('Error reading bag: ' + error);
    });
    this.getBagInfo();
  }

  async getBagInfo() {
    this.bagsService.getBag(this.bagId).subscribe((res) => {
      this.bag = res;
      console.log(this.bag);
    });
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }


  initAllowedIds() {
    // Do not allow any app by default
    this.allowedCompanyIds = [];
    this.allowAllCompanyIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedCompanyIds = null;
      this.allowAllCompanyIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedCompanyIds = profile.authorized_apps || [];
  }

  onCreateTransation(item){
    console.log(item);
    this.router.navigate(['pages', 'bagtransaction', 'addTransaction', item]);
  }

  typeTransaction(item){
    return OPTIONS_TYPES_GENERALES[item];
  }

  type(item){
    return OPTIONS_TYPESMONT[item];
  }

}

