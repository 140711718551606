import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFormExhibitions } from '../exhibition/exhibition.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExhibitionsService {
  header = new HttpHeaders({ 'Content-Type':'application/json'});
  constructor(private http: HttpClient) {}
  

  getForms(appid){
    const uriConnection = `${environment.exhibition_api.base_url}/${appid}/forms`;
    let headers = this.header
    return this.http.get<{data:IFormExhibitions[]}>(`${uriConnection}`, { headers });
  }
  
  getForm(appid,id: string):Observable< IFormExhibitions> {
    let headers = this.header
    const uri = `${environment.exhibition_api.base_url}/${appid}/form/${id}`;;
    return this.http.get<IFormExhibitions>(uri,{ headers });
  }

  putForm(form: IFormExhibitions, app_id) {
    const uriConnection = `${environment.exhibition_api.base_url}/${app_id}/form`;
    return this.http.put<IFormExhibitions>(
      `${uriConnection}/${form.id || 'default'}`,
      form
    );
  }
}
