import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-consolidado-puntos-pedidos',
  templateUrl: './consolidado-puntos-pedidos.component.html',
  styleUrls: ['./consolidado-puntos-pedidos.component.scss']
})
export class ConsolidadoPuntosPedidosComponent implements OnInit {

  width = (window.innerWidth - 30)
  constructor() { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;
    this.width = (width - 30)
  }

  ngOnInit(): void {
    
  }
}
