/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information.
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.cop
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OpenIDInfo } from "./openIDInfo";
import { UnconstrainedApp } from "./unconstrainedApp";
import { AppAllOf } from "./appAllOf";

export interface App {
  name: string;
  id: string;
  gcp: {
    main_project_id: string;
    recaptcha_auth: boolean;
    recaptcha_secret: string;
    recaptcha_enterprise: boolean;
    recaptcha_min_score: number;
    recaptcha_keys_ids: string[];
  };
  auth_jwt_info: {
    issuer: string;
    audiences: string[];
    jwks_uri: string;
  };
  register_type?: string;
  puntored_recaudo:{
    factor:number;
    offset_cents : number;
  }
  card_terminal:{
    factor:number;
    offset_cents : number;
  }
}
