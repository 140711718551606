import { Injectable, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {StorageService} from '../../services/storage.service';
import {ChallengeStatusType, ExhibitionAnswer} from '../../models/answers/answers-models';
@Injectable({
  providedIn: 'root'
})
export class ExhibitionsService {

  constructor(
    @Inject('ExhibitionsFirestore') private exhibitionsFire: AngularFirestore,
    private storageService: StorageService,
  ) { }

  updateExhibitionsStatus(id: string, newStatus: ChallengeStatusType,dataConfirmation?) {
    const account = this.storageService.get('account');
    if (!account) {
      throw new Error('No se encuentran los datos del usuario actual.');
    }
    const db = this.exhibitionsFire.firestore;
    const docRef = db.doc(`ExhibitionsAnswers/${id}`);
    const data  =  {
      status: newStatus,
      rejectionCause: dataConfirmation.rejectionCause,
      comment: dataConfirmation.comment,
      updatedBy: {
        id: account.id,
        appId: account.app_id,
        username: account.username,
      },
      checker: {
        id: account.id,
        username: account.username,
        designateBy: {
          id: null,
          username: null,
        }
      },
      updatedAt: new Date(),
      statusHistory : dataConfirmation.statusHistory
    };
    data.statusHistory[newStatus] = new Date();
    return docRef.update(data);
  }

  fieldTextValue(val: any) {
    const txtVal = `${val}`;
    const maxlen = 20;
    const slicedVal = txtVal.slice(0, maxlen) + (txtVal.length > maxlen ? '...' : '');
    return slicedVal;
  }

  async verifyId( newId){
    const db = this.exhibitionsFire.firestore;
    const docRef = db.collection('ExhibitionsAnswers')
      .where('exhibitionConfirmation.exhibitionIdConfirmation', '==', newId)
      .get();
    return await docRef;

  }
}
